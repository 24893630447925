// import ChatPage from "components/ChatPage";
import ChatPage from "components/ChatPageV2";
import React from "react";
import { useParams } from "react-router";

export default function Chatbot() {
  const { sessionId } = useParams();

  return (
    <div>
      <ChatPage
        place="detail"
        onSessionCreate={(id) => {}}
        sessionUid={sessionId || ""}
      />
    </div>
  );
}
