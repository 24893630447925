import Button from "components/Button";
import ZSkel from "components/ZSkel";
import { mainContent } from "components/variants";
import { useAuth } from "context/AuthContext";
import { useWorkspace } from "context/WorkspaceContext";
import { getDashboardStatsApi } from "network/api/workspace/dashboard";
import React, { useEffect, useState } from "react";
import {
  BsFillArrowUpRightCircleFill,
  BsFillChatDotsFill,
  BsFillFileEarmarkTextFill,
  BsPeopleFill,
} from "react-icons/bs";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { tv } from "tailwind-variants";
import { ApiRes } from "types";
import { StatsD } from "types/project";

export default function DashboardPage() {
  const { token } = useAuth();
  const { projectUid } = useWorkspace();
  const [data, setData] = useState<StatsD>({} as StatsD);
  const [loading, setLoading] = useState(false);

  const BOXES = [
    {
      id: 1,
      label: "Total files",
      key: "total_documents",
      icon: <BsFillFileEarmarkTextFill size={20} />,
      link: `/workspace/${projectUid}/files`,
    },
    {
      id: 2,
      label: "Total conversation",
      key: "total_conversation",
      icon: <BsPeopleFill size={20} />,
      link: `/workspace/${projectUid}/conversations`,
    },
    {
      id: 3,
      label: "Total Queries",
      key: "query_count",
      icon: <BsFillChatDotsFill size={20} />,
    },
  ];

  useEffect(() => {
    if (!token || !projectUid) return;

    const getStats = async () => {
      try {
        setLoading(true);
        const res = await getDashboardStatsApi({ token, data: { projectUid } });
        setLoading(false);
        if (res.type === ApiRes.SUCCESS) {
          setData(res?.data[0]);
        }
      } catch (err) {
        setLoading(false);
        console.error("Err", err);
      }
    };

    getStats();
  }, [token, projectUid]);

  const nData: any = data;

  return (
    <div
      className={mainContent({
        place: "dash",
        class: " flex-col flex gap-8 max-w-[900px] mx-auto",
      })}
    >
      <div>
        <h4 className="font-semibold mb-2">Stats</h4>

        <div className={boxesV()}>
          {loading ? (
            <>
              {BOXES.map((i) => {
                return (
                  <div
                    className={boxV({
                      class: "h-[110px] relative overflow-hidden",
                    })}
                  >
                    <ZSkel />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {BOXES.map((i) => {
                return (
                  <div className={boxV()}>
                    <div className={"self-start text-blue-600 mt-1"}>
                      {i.icon}
                    </div>
                    <div className="flex-1">
                      <h4 className={boxTV()}>{i.label}</h4>
                      <div className={boxCV()}>{nData[i.key]}</div>
                    </div>
                    {i.link && (
                      <Link to={i.link} className={arrowV()}>
                        <BsFillArrowUpRightCircleFill size={24} />
                      </Link>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      {/* QUICK LINKS  */}

      <div>
        <h4 className="font-semibold mb-2">Quick Links</h4>

        <div className="flex gap-4">
          <div className="bg-gray-50 flex-1 hover:ring-1 hover:bg-blue-50 ring-blue-400 transition-all rounded-xl p-4 flex-col flex gap-4 items-start">
            <div className="">
              <h4 className="font-semibold ">Add Documents & Train</h4>
              <p className="opacity-70 text-sm">
                Enhance your QA Expert by uploading data sets and training
                models.
              </p>
            </div>
            <Link to={`/workspace/${projectUid}/files`}>
              <Button>Add documents</Button>
            </Link>
          </div>

          <div className="bg-gray-100 flex-1 hover:ring-1 hover:bg-blue-50 ring-blue-400 transition-all rounded-lg p-4 flex-col flex gap-4 items-start">
            <div className="">
              <h4 className="font-semibold ">Ask Queries</h4>
              <p className="opacity-70 text-sm">
                Enhance your QA Expert by uploading data sets and training
                models.
              </p>
            </div>
            <Link to={`/workspace/${projectUid}/query`}>
              <Button>Ask query</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const boxesV = tv({
  base: "grid grid-cols-3 gap-4 ",
});
const boxV = tv({
  base: "rounded-2xl p-5 border-gray-100 border flex gap-2 justify-between items-center group hover:border-gray-300 duration-100",
});
const boxTV = tv({
  base: " mb-2 text-gray-600 font-medium ",
});
const boxCV = tv({
  base: "text-2xl font-bold",
});
const arrowV = tv({
  base: "text-gray-400 hover:text-blue-500 transition-all group-hover:ring-2 ring-gray-100 hover:ring-blue-300 rounded-full group-hover:ring-offset-1 cursor-pointer h-[42px] w-[42px] flex items-center justify-center",
});

const boxIcon = tv({});
