export const THEME = {
  sidebarWidth: 260,
  appbarHeight: {
    outer: 60,
    workspace: 60,
  },
  infoBarHeight: 50,
  contentMargin: {
    x: 10,
    y: 10,
  },
};
