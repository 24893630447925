export type SubscriptionD = {
  id: number;
  subscription_id: string;
  app_id: number;
  organization_id: number;
  canceled_at: number;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer_id: string;
  user_id: string;
  project_id: null;
  plan_id: number;
  status: string;
  stripe_plan_id: string;
  plan_name: string;
  subscription_benefits: string;
  createdAt: string;
  updatedAt: string;
  user: null;
};

export enum PlansE {
  trial = "qa_basic_trial",
  starter_monthly = "qa_starter_monthly",
  // basic_monthly = "qa_basic_monthly",
  essential_monthly = "qa_essential_monthly",
  // growth_monthly = "qa_growth_monthly",
  professional_monthly = "qa_professional_monthly",
  elite_monthly = "qa_elite_monthly",
}

export enum SubscriptionStatus {
  active = "active",
  cancel = "cancel",
  incomplete = "incomplete",
  paused = "paused",
  trialing = "trialing",
  incomplete_expired = "incomplete_expired",
  complete = "complete",
}

type NextBillingCycle = {
  id: number;
  invoice_id: null | string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: string;
  created: number;
  period_start: number;
  period_end: number;
  billing_reason: string;
  currency: string;
  customer_id: string;
  transaction_id: null | string;
  customer_email: string;
  customer_name: string;
  subscription_id: string;
  status: string;
  payment_intent: null | string;
  total: number;
  subtotal: number;
  invoice_pdf: null | string;
  app_id: number;
  organization_id: number;
  due_date: null;
  paid: boolean;
  createdAt: Date;
  updatedAt: Date;
};
export type ActivePlanD = {
  usage: {
    characters: {
      limit: string;
      usage: string;
    };
    workspace: {
      limit: string;
      usage: string;
    };
    document: {
      limit: string;
      usage: string;
    };
    credits: {
      limit: string;
      usage: string;
    };
  };
  plan: PlansE;
  plan_id: string;
  paid_invoice: NextBillingCycle[];
  next_billing_cycle: NextBillingCycle;
  status: null | SubscriptionStatus;
  trail_plan: null | {
    app_id: 2;
    createdAt: string;
    expiry_date: Date;
    id: 1;
    organization_id: number;
    plan_id: string;
    project_id: any;
    status: SubscriptionStatus;
    user_id: number;
  };
};
