import { Alert, Button, CircularProgress } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { isValidFileType, logErr } from "utils/helpers";
import {
  getProjectFileSignedUrlApi,
  uploadProjectFileApi,
} from "network/api/workspace/file";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "context/AuthContext";
import { useWorkspace } from "context/WorkspaceContext";
import { useNavigate } from "react-router-dom";
import { mainContent } from "components/variants";
import styled from "styled-components";
import { FaUpload } from "react-icons/fa";
import { ApiRes } from "types";
import { useApp } from "context/AppContext";
import { SubscriptionStatus } from "types/subscription";

let controller: any;

export default function AddProfileFile() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { openUpgrade, activePlan } = useApp();
  const { projectUid, projectDetail } = useWorkspace();
  const [file, setFile] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const showToast = useCallback(
    ({ text }: { text: string }) => {
      return toast(
        <div className="">
          <p className="mb-3">{text}</p>
          <div className="flex items-center gap-3">
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                openUpgrade();
                toast.dismiss();
              }}
            >
              Upgrade now
            </Button>
            <Button
              color="primary"
              variant="plain"
              onClick={() => {
                toast.dismiss();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>,
        {
          duration: Infinity,
        }
      );
    },
    [openUpgrade]
  );

  // FUNCTIONS
  const onFile = async (e: any) => {
    // if (activePlanDetails) {
    //   if (Number(activePlanDetails?.usage?.document?.usage) >= Number(activePlanDetails?.usage?.document?.limit)) {
    //     showToast({ text: "Your have already reached the limit. Kindly upgrade to continue." });
    //     e.target.value = null;
    //     return;
    //   }
    // }

    if (e.target.files[0]) {
      const fileSize = e.target.files[0].size / 1024 / 1024;
      // const maxFileSize = 50;
      const maxFileSize =
        activePlan?.status === SubscriptionStatus.trialing ? 1 : 50;

      if (fileSize <= maxFileSize) {
        if (!isValidFileType(e.target.files[0]?.name)) {
          alert("Not a valid file type");
          e.target.value = null;
        } else {
          setFile(e.target.files[0]);
        }
        //   } else {
        //     if (activePlanDetails?.plan_id === PAYMENT_PLANS_IDS.basic_monthly) {
        //       showToast({ text: "Your max file size limit is 1mb. Upgrade to upload files upto 10mb." });
        //       e.target.value = null;
        //     } else if (maxFileSize >= 10) {
        //       toast.error("Max file size exceeded - 10mb");
        //     }
      } else {
        toast.error(`Max file size exceeded - ${maxFileSize}mb`);
        e.target.value = null;
      }
    }
  };
  const cancelUpload = () => {
    controller?.abort();
  };

  // SIDE EFFECTS
  useEffect(() => {
    const onUpload = async () => {
      if (!projectUid) {
        return;
      }

      try {
        setUploading(true);
        const res1 = await getProjectFileSignedUrlApi({
          file_name: file.name,
          project_uid: projectUid,
          token,
        });

        controller = new AbortController();
        const config = {
          signal: controller.signal,
          onUploadProgress: (progressEvent: any) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
          headers: {
            "Content-Type": "application/octet-stream",
          },
        };

        await axios.put(res1?.data?.url, file, config);

        let res;

        res = await uploadProjectFileApi({
          token,
          file_name: res1?.data?.filename,
          original_filename: file.name,
          project_uid: projectUid,
        });

        if (res.type === ApiRes.SUCCESS) {
          toast("File uploaded", {
            icon: "✅",
          });
          navigate(-1);
        }
        if (res.type === ApiRes.ERROR && res?.status === 409) {
          showToast({ text: res?.message });
        }

        setUploading(false);
      } catch (err) {
        setUploading(false);
        logErr("Err", err);
      }
    };

    if (file && token && projectUid) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          onUpload();
        },
        false
      );

      if (file) {
        reader.readAsText(file);
      }
    }
  }, [file, projectDetail?.purpose, navigate, projectUid, token, showToast]);

  return (
    <Root className={mainContent({})}>
      <InputBox>
        <div className="text-5xl mb-8 ">
          {/* <Logo className="h-14 w-auto opacity-30" /> */}
        </div>
        <UploadFileBtn htmlFor="myFile">
          <FaUpload /> Upload File
        </UploadFileBtn>
        <div className="mt-2 text-lg opacity-60">or drop a file</div>
        <input
          type="file"
          id="myFile"
          accept="text/plain,.txt, .pdf, .doc, .docx, .ppt, .csv"
          className="absolute top-0 left-0 w-full h-full opacity-0"
          onChange={onFile}
        />

        {uploading && (
          <Overlay>
            <div className="w-[300px] mx-auto">
              <CircularProgress size="lg" determinate value={uploadProgress}>
                {uploadProgress}%
              </CircularProgress>
            </div>
            <div className="flex items-center justify-center gap-3">
              <Button
                size="md"
                variant="plain"
                color="danger"
                onClick={cancelUpload}
              >
                Cancel
              </Button>
            </div>
          </Overlay>
        )}
      </InputBox>
      <Alert color="info" sx={{ maxWidth: 900, margin: "0 auto" }}>
        Supported file formats: txt, pdf, doc, docx, ppt, csv
      </Alert>
    </Root>
  );
}

const Root = styled.div`
  text-align: center;
  h1 {
    /* font-size: 2.25rem; */
    font-size: 20px;
    /* font-weight: bold;  */
    opacity: 70%;
    max-width: 700px;
    line-height: 105%;
    margin: 0 auto;
  }
`;
const InputBox = styled.div`
  position: relative;
  cursor: pointer;
  /* box-shadow: 0 0 40px rgba(0,0,0,.1);
    box-shadow: 0 0 15px rgba(0,0,0,.1); */
  border: 0.5px dashed rgba(0, 0, 0, 0.1);
  max-width: 500px;
  max-width: 900px;
  /* max-width: 100%; */
  border-radius: 20px;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  min-height: 250px;
  padding: 2em;
  margin: 2em auto;
  overflow: hidden;
  background-color: #f3f3f3;
`;
const UploadFileBtn = styled.label`
  display: inline-block;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  color: #fff;
  background-color: #1865ea;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.25rem;
  font-weight: 600;
  /* margin-top: auto; */

  &:hover {
    background-color: #134fb5;
    transition: all 0.3s ease;
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
  }
`;
const Overlay = styled.div`
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: column;
  padding: 1em;
  /* align-items: center; */
  justify-content: center;
  gap: 1em;
`;
