import { Outlet } from "react-router-dom";
import Appbar from "../Appbar";
import InfoBar from "components/InfoBar";

export default function OuterLayout() {
  return (
    <div>
      <div className="sticky top-0">
        <Appbar />
        <InfoBar />
      </div>

      <main>
        <Outlet />
      </main>
    </div>
  );
}
