import { Button, Modal, ModalClose, Sheet } from "@mui/joy";
import OverlayLoader from "components/OverlayLoader";
import { useApp } from "context/AppContext";
import { useAuth } from "context/AuthContext";
import { createSubscriptionApi, updateSubscriptionApi } from "network/api/subscriptions";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { Link } from "react-router-dom";
import { tv } from "tailwind-variants";
import { ApiRes } from "types";
import { PlansE, SubscriptionStatus } from "types/subscription";
import { PLANS } from "utils/constants";
import { formatNumberShort } from "utils/helpers";

export default function PlansList({ message = "" }) {
  const [loading, setLoading] = useState<"pro" | "trial" | "update" | "">("");
  const { token } = useAuth();
  const { orgId, activePlan, fetchPlan } = useApp();
  const [selectedPlan, setSelectedPlan] = useState<any>(PLANS.qa_essential_monthly);
  const [openModal, setOpenModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);

  const onTrial = async () => {
    try {
      setLoading("trial");
      const res = await createSubscriptionApi({
        plan: PlansE.trial,
        id: orgId.toString(),
        token,
      });

      if (res.type === ApiRes.SUCCESS) {
        // setActivePlan(res.data);
        fetchPlan();
      }
      if (res.type === ApiRes.ERROR) {
        toast.error(res?.message);
      }
      setLoading("");
    } catch (err) {
      setLoading("");
      console.error("Error", err);
    }

    // try {
    //   setLoading("trial");
    //   const res = await createSubscriptionApi({
    //     id: orgId.toString(),
    //     plan: PLANS.qa_starter_monthly.key,
    //     token,
    //     is_trial: "true",
    //   });

    //   if (res.type === ApiRes.SUCCESS) {
    //     // setActivePlan(res.data);
    //     fetchPlan();
    //   }
    //   if (res.type === ApiRes.ERROR) {
    //     toast.error(res?.message);
    //   }
    //   setLoading("");
    // } catch (err) {
    //   setLoading("");
    //   console.log("Err", err);
    // }
  };

  const onPro = async () => {
    try {
      setLoading("pro");
      const res = await createSubscriptionApi({
        id: orgId.toString(),
        plan: selectedPlan?.key,
        token,
      });

      if (res.type === ApiRes.SUCCESS) {
        window.open(res.data.url, "_self");
      }
      if (res.type === ApiRes.ERROR) {
        toast.error(res?.message);
      }

      setLoading("");
    } catch (err) {
      setLoading("");
      console.log("Err", err);
    }
  };

  const onUpdate = async () => {
    try {
      setLoading("update");
      const res = await updateSubscriptionApi({
        id: orgId.toString(),
        plan: selectedPlan?.key,
        token,
      });

      if (res.type === ApiRes.SUCCESS) {
        window.open(res.data.url, "_self");
      }
      if (res.type === ApiRes.ERROR) {
        toast.error(res?.message);
      }
      setLoading("");
      setOpenModal(false);
    } catch (err) {
      setLoading("");
      console.error(err);
    }
  };

  return (
    <div className="max-w-[1100px] min-h-[400px] mx-auto flex rounded-2xl  overflow-hidden bg-gray-100 relative">
      {loading && <OverlayLoader dark />}

      {!activePlan && (
        <div className="px-4 flex flex-col items-center justify-center basis-[300px]">
          <div className="">
            <h4 className="font-medium text-lg mb-2">Start your 7 days trail plans</h4>
            <ul className="list-disc pl-6 mb-4 text-sm">
              <li>{PLANS.qa_basic_trial.workspace} workspace</li>
              <li>{PLANS.qa_basic_trial.document} files</li>
              <li>{PLANS.qa_basic_trial.credits} credits</li>
            </ul>
            <Button variant="outlined" onClick={onTrial} loading={loading === "trial"}>
              Start free trial
            </Button>
          </div>
        </div>
      )}
      <div className="flex-1 bg-blue-600 p-6 flex flex-col justify-center items-center relative">
        <div className="">
          <h4 className="font-semibold mb-4 text-white/80">{message?.length ? message : "Pick your premium plan"}</h4>

          <div className="plans gap-2 grid grid-cols-2 md:grid-cols-4">
            {Object.values(PLANS)
              .filter((i) => !i.trail)
              .map((i, index) => {
                return (
                  <div
                    key={index}
                    className={planItemV({
                      type: selectedPlan?.planId ? (selectedPlan?.planId === i.planId ? "active" : "n") : Number(activePlan?.plan_id) === i.planId ? "active" : "n",
                      // type: selectedPlan?.planId ? (selectedPlan?.planId === i.planId ? "active" : "n") : Number(activePlan?.plan_id) === i.planId ? "active" : "n",
                    })}
                    onClick={() => setSelectedPlan(i)}
                  >
                    <div className="font-medium mb-1 text-sm">{i?.name}</div>
                    <h3 className="font-semibold text-lg mb-4">${i.price} / month</h3>
                    <ul className="list-disc pl-2 mb-4 text-sm">
                      <li>{i.workspace === Infinity ? "Unlimited" : i.workspace} workspace</li>
                      <li>{i.document} files</li>
                      <li>{formatNumberShort(i.characters)} characters</li>
                      <li>{i.credits} credits</li>
                    </ul>
                  </div>
                );
              })}
          </div>

          <button
            onClick={() => {
              if (activePlan?.status === SubscriptionStatus.active) {
                if (Number(selectedPlan?.planId) < Number(activePlan?.plan_id)) {
                  setOpenContactModal(true);
                } else {
                  setOpenModal(true);
                }
              } else {
                onPro();
              }
            }}
            className="text-black text-sm border-none outline-none bg-white font-medium hover:shadow-md mt-4 rounded-md px-3 py-2"
          >
            {activePlan?.status === SubscriptionStatus.active ? "Upgrade Plan" : "Proceed to payment"}
          </button>
        </div>
      </div>

      {/* Upgrade modal */}
      <Modal
        aria-labelledby="close-modal-title"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: "md",
            px: 5,
            py: 3,
            maxWidth: "90%",
            width: 500,
            position: "relative",
            zIndex: 10,
            overflowY: "auto",
          }}
        >
          <ModalClose variant="outlined" />
          <div>
            <div className="text-xl font-bold">Change Plan</div>
            <div className="my-5">You are about to unlock new subscription features and benefits. Please review your new subscription plan details below and click 'Confirm' to proceed with the upgrade.</div>
            <div className="text-black/70">
              Subscription Plan: <span className="text-black font-medium">{selectedPlan?.name}</span>
            </div>
            <div className="text-black/70 mt-2">
              Benefits:{" "}
              <span className="text-black font-medium">
                {selectedPlan?.workspace} workspaces, {selectedPlan?.document} documents and {selectedPlan?.credits} credits
              </span>
            </div>

            <button
              onClick={() => {
                onUpdate();
              }}
              className={`transition-all mt-5 cursor-pointer bg-green-600 hover:bg-green-700 max-w-[200px] font-weight px-14 w-full py-3 font-semibold rounded-3xl text-white`}
            >
              {loading ? <CgSpinner className="animate-spin text-center text-xl mx-auto" /> : "Confirm"}
            </button>
          </div>
        </Sheet>
      </Modal>

      {/* Contact us modal */}
      <Modal
        aria-labelledby="close-modal-title"
        open={openContactModal}
        onClose={() => {
          setOpenContactModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: "md",
            px: 5,
            py: 3,
            maxWidth: "90%",
            width: 500,
            position: "relative",
            zIndex: 10,
            overflowY: "auto",
          }}
        >
          <ModalClose variant="outlined" />
          <div>
            <div className="text-xl font-bold">Contact Us</div>
            <div className="my-5">
              If you wish to downgrade your plan, we're here to assist you. Contact our customer support team, and they will guide you through the process. Your satisfaction is our priority, and we'll ensure a smooth transition to a more
              suitable plan for your needs.
            </div>

            <Link to="https://yourgpt.ai/contact">
              <Button>Contact us</Button>
            </Link>
          </div>
        </Sheet>
      </Modal>
    </div>
  );
}

const planItemV = tv({
  base: "border border-white/30 text-white/80 p-4 rounded-md px-8 py-3 cursor-pointer transition-all hover:border-gray-400",
  variants: {
    type: {
      active: " text-black border-blue-500  bg-blue-50 hover:border-blue-600 ",
      n: "",
    },
  },
});
