import { ClickAwayListener } from "@mui/base";
import { Menu } from "@mui/joy";
import LoadingDots from "components/LoadingDots";
import { useAuth } from "context/AuthContext";
import { useWorkspace } from "context/WorkspaceContext";
import { getMyWorkspacesApi } from "network/api/workspace";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { ApiRes } from "types";
import { ProjectD } from "types/project";

import { useDebounce } from "use-debounce";

export default function WorkSpaceDropdown() {
  const { projectDetail, loadingDetail } = useWorkspace();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { token } = useAuth();

  /**
   *  FETCHIG
   */
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ProjectD[]>([]);
  const [search, setSearch] = useState("");
  const [delaySearch] = useDebounce(search, 300);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    let active = true;

    const fetchWorkspaces = async () => {
      if (!token) {
        return;
      }

      try {
        if (search) {
          setSearching(true);
        } else {
          setLoading(true);
        }

        const res = await getMyWorkspacesApi({
          token,
          search: delaySearch,
          limit: 10,
        });

        setLoading(false);
        setSearching(false);
        if (res.type === ApiRes.SUCCESS && active) {
          setList(res.data);
        }
      } catch (err) {
        setSearching(false);
        setLoading(false);
        console.error("Err", err);
      }
    };

    if (open) {
      fetchWorkspaces();
    }
    return () => {
      active = false;
    };
  }, [token, delaySearch, open]);

  return (
    <>
      <div
        className=" px-3 rounded-lg py-2 flex items-center cursor-pointer hover:bg-white/5"
        onClick={(e) => {
          if (open) {
            setAnchorEl(null);
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <div className="flex-1">
          <div className="text-xs text-white/50 mb-1">Workspace</div>
          <div className="text-white flex items-center ">
            {loadingDetail ? (
              <div className="py-2 flex justify-center">
                {" "}
                <LoadingDots light />
              </div>
            ) : (
              projectDetail?.name
            )}
          </div>
        </div>
        <div className="text-white/40">
          <IoIosArrowDown className={`${open ? "rotate-180" : ""} transition-all`} />
        </div>
      </div>

      <Menu size="md" disablePortal id="basic-menu" sx={{ width: "90%", boxShadow: "none" }} anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} aria-labelledby="basic-demo-button">
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
          }}
        >
          <div>
            <div className="py-2 px-2">
              <input
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                type="search"
                placeholder="Search workspace"
                className="bg-gray-200 border-none outline-none rounded-lg px-1 py-2 w-full text-sm"
              />
            </div>

            <div className="flex flex-col">
              {list
                .filter((i) => i.id !== projectDetail?.id)
                .map((i) => {
                  return (
                    <Link
                      to={`/workspace/${i.project_uid}/dashboard`}
                      onClick={() => {
                        setAnchorEl(null);
                      }}
                      className="p-2 text-sm"
                    >
                      {i?.name}
                    </Link>
                  );
                })}
            </div>
            {(loading || searching) && (
              <div className="flex justify-center py-4">
                <LoadingDots />
              </div>
            )}
          </div>
        </ClickAwayListener>
      </Menu>
    </>
  );
}
