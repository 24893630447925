import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthContext";
import { getActivePlanApi } from "network/api/subscriptions";
import { getOrganisationApi } from "network/api/app";
import { ApiRes } from "types";
import { ActivePlanD, SubscriptionStatus } from "types/subscription";
import PlansModal from "components/Modals/PlansModal";
import { isPast } from "date-fns";

const AppContext = createContext({} as AppContextD);

export default function AppProvider({ children }: any) {
  const { token, logout } = useAuth();
  const [orgId, setOrgId] = useState(0);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [activePlan, setActivePlan] = useState<ActivePlanD | null>(null);
  const [planShowMessage, setPlanShowMessage] = useState("");
  const [openPlansModal, setOpenPlansModal] = useState(false);
  const [trialExpired, setTrialExpired] = useState(false);

  const openUpgrade = (message?: string) => {
    setOpenPlansModal(true);
    if (message) {
      setPlanShowMessage(message);
    }
  };

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const res = await getOrganisationApi({
          limit: 10,
          page: 1,
          token,
        });
        if (res.type === ApiRes.SUCCESS) {
          setOrgId(res.data[0].id);
        }
        if (res.type === ApiRes.ERROR && res?.code === 401) {
          logout();
        }
      } catch (err) {
        console.error("Err", err);
      }
    };

    if (token) {
      fetchOrg();
    }
  }, [token, logout]);

  const fetchPlan = useCallback(async () => {
    try {
      if (!token || !orgId) {
        return;
      }

      const res = await getActivePlanApi({
        token,
        type: "organization",
        id: orgId.toString(),
      });

      setLoadingPlan(false);

      if (res.type === ApiRes.SUCCESS) {
        setActivePlan(res.data);
        setTrialExpired(
          res?.data?.status !== SubscriptionStatus.active &&
            res?.data?.trail_plan?.expiry_date
            ? isPast(new Date(res?.data?.trail_plan?.expiry_date))
            : false
        );
      }
      if (res.type === ApiRes.ERROR && res?.code === 401) {
        logout();
      }
    } catch (err) {
      console.error("Err", err);
      setLoadingPlan(false);
    }
  }, [token, orgId, logout]);

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  return (
    <AppContext.Provider
      value={{
        loadingPlan,
        activePlan,
        orgId,
        setActivePlan,
        fetchPlan,
        openUpgrade,
        trialExpired,
      }}
    >
      {children}
      <PlansModal
        open={openPlansModal}
        onClose={() => setOpenPlansModal(false)}
        message={planShowMessage}
      />
    </AppContext.Provider>
  );
}

export function useApp() {
  return useContext(AppContext);
}

type AppContextD = {
  loadingPlan: boolean;
  activePlan: null | ActivePlanD;
  orgId: number;
  setActivePlan: Dispatch<SetStateAction<ActivePlanD | null>>;
  fetchPlan: () => any;
  openUpgrade: (str?: string) => void;
  trialExpired: boolean;
};
