import styled from "@emotion/styled";
import { CircularProgress, IconButton, Input, Menu, MenuItem } from "@mui/joy";
import { useWorkspace } from "context/WorkspaceContext";
import { useCallback, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoFilter } from "react-icons/io5";
import { RiArrowDownSLine } from "react-icons/ri";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { ApiRes, SortD } from "types";
import { getProjectSessionsApi } from "network/api/workspace/session";
import { useAuth } from "context/AuthContext";
import { mainContent } from "components/variants";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { FiCornerLeftDown } from "react-icons/fi";
import { formatDateTime } from "utils/helpers";
import { useDebounce } from "use-debounce";

const LIMIT = 5;

export default function Conversations() {
  const { projectUid } = useWorkspace();
  const { token } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const menuOpen = Boolean(anchorEl);
  const [status, setStatus] = useState<undefined | string>();
  const [sort, setSort] = useState<SortD>("desc");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [delaySearch] = useDebounce(search, 800);

  //DATA FETCHING
  const fetchSessions = useCallback(async () => {
    try {
      if (!projectUid || !token) {
        return;
      }

      setLoading(true);
      const res = await getProjectSessionsApi({
        project_uid: projectUid,
        token,
        limit: LIMIT,
        status,
        orderBy: sort,
        page,
        search: delaySearch,
      });

      if (res.type === ApiRes.SUCCESS) {
        setList(res.data);
        setTotal(res.total);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Err", err);
    }
  }, [page, sort, status, projectUid, token, delaySearch]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  return (
    <Root
      className={mainContent({ place: "dash", class: "max-w-[90%] mx-auto" })}
    >
      <div className="flex mb-5 items-center">
        <h2 className="mr-auto text-md font-semibold opacity-70">
          All Conversations
        </h2>
        {/* <Input
          placeholder="Search..."
          size="sm"
          sx={{ minWidth: 250 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        /> */}
        <div
          className="flex items-center gap-2 cursor-pointer text-black/70 border border-[#D8D8DF] text-sm p-[0.3rem] px-2 ml-2 rounded-lg hover:bg-gray-200 hover:border-[#c9c9d0]"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <IoFilter />
          <span>Sort by</span>
          <RiArrowDownSLine
            className={`${menuOpen ? "rotate-180 duration-200" : ""}`}
          />
        </div>
        <Menu
          size="sm"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
          sx={{
            minWidth: 120,
          }}
        >
          <MenuItem
            sx={{ ":focus-visible": { outline: "none" } }}
            color="neutral"
            onClick={() => {
              setSort("desc");
              setAnchorEl(null);
            }}
          >
            Recent
          </MenuItem>
          <MenuItem
            sx={{ ":focus-visible": { outline: "none" } }}
            color="neutral"
            onClick={() => {
              setSort("asc");
              setAnchorEl(null);
            }}
          >
            Oldest
          </MenuItem>
        </Menu>
      </div>

      <div>
        {loading ? (
          <div className="flex h-20 items-center justify-center rounded-xl">
            <CircularProgress size="md" />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              {list.map((i) => {
                return (
                  <div
                    className="hover:bg-gray-100 rounded-md flex flex-col gap-2  p-2 px-4 group transition-all border border-gray-200 cursor-pointer"
                    onClick={() => {
                      const p = pathname.split("/");
                      p.pop();
                      // console.log("p", `${p.join("/")}/c/${i.session_uid}`);

                      navigate(`${p.join("/")}/c/${i.session_uid}`);
                    }}
                  >
                    <div className="top flex items-center">
                      <div className="flex gap-2">
                        <div className="mt-3">
                          <FiCornerLeftDown />
                        </div>

                        <div className="max-w-[400px]">
                          {Number(i.total_messages) > 0 ? (
                            <>
                              <div className="text-base font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                                {i.first_message}
                              </div>
                              <div className="text-gray-600 text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                                {i.reply_message}
                              </div>
                            </>
                          ) : (
                            <div className="text-black/70">No messages yet</div>
                          )}

                          <div className="text-sm text-gray-400 font-medium mt-3">
                            {formatDateTime(i.created_at)}
                          </div>
                        </div>
                      </div>

                      <div className="right ml-auto">
                        <IconButton
                          variant="soft"
                          sx={{ borderRadius: 120, transition: "all 0.2s" }}
                        >
                          <BsFillArrowRightCircleFill size={20} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {total / LIMIT > 1 && (
              <div className="flex gap-2 items-center py-4 justify-center opacity-80">
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                >
                  <AiOutlineArrowLeft />
                </IconButton>
                <div className="font-semibold text-sm">
                  {page}/{Math.ceil(total / LIMIT)}
                </div>
                <IconButton
                  color="neutral"
                  variant="plain"
                  onClick={() => setPage(page + 1)}
                >
                  <AiOutlineArrowRight />
                </IconButton>
              </div>
            )}
          </>
        )}
      </div>
    </Root>
  );
}

const Root = styled.div`
  .unseen {
    background: #eee;
  }
`;
