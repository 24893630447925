import OuterLayout from "components/layouts/OuterLayout";
import WorkspaceLayout from "components/layouts/WorkspaceLayout";
import AppProvider from "context/AppContext";
import AuthProvider from "context/AuthContext";
import WorkspaceProvider from "context/WorkspaceContext";
import DashboardPage from "pages/dashboard";
import CollabChat from "pages/shared";
import AddProfileFile from "pages/workspace/file/add";
import FilesList from "pages/workspace/file/list";
import Integration from "pages/workspace/integration";
import Query from "pages/workspace/query";
import SessionDetail from "pages/workspace/conversation/detail";
import Workspaces from "pages/workspaces";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import Conversations from "pages/workspace/conversation/list";
import PaymentError from "pages/external/payment-error";
import PaymentSuccess from "pages/external/payment-success";
import Billing from "pages/external/billing";
import PlansPage from "pages/external/plans";
import DiscordIntegrationStatus from "pages/external/discordStatus";
import SlackIntegrationStatus from "pages/external/slackStatus";
import NotFound from "pages/NotFound";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        element={
          // <Provider store={store}>
          <AuthProvider>
            <AppProvider>
              <WorkspaceProvider>
                <Outlet />
              </WorkspaceProvider>
            </AppProvider>
            {/* </ProjectProvider> */}
          </AuthProvider>
          // </Provider>
        }
      >
        {/* OUTER  */}
        <Route path="/">
          {/* EXTERNALS  */}
          <Route
            path="/integration/discord/status"
            element={<DiscordIntegrationStatus />}
          />
          <Route
            path="/integration/slack/status"
            element={<SlackIntegrationStatus />}
          />

          <Route element={<OuterLayout />}>
            <Route path="" element={<Workspaces />} />
            <Route path="payment-success" element={<PaymentSuccess />} />
            <Route path="payment-error" element={<PaymentError />} />
            <Route path="billing" element={<Billing />} />
            <Route path="plans" element={<PlansPage />} />
          </Route>
        </Route>

        {/* INTERNALS  */}
        <Route path="/workspace/:projectUid">
          <Route element={<WorkspaceLayout />}>
            <Route
              path=""
              element={<Navigate relative="route" replace to={"dashboard"} />}
            />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="integration" element={<Integration />} />
            <Route path="files" element={<FilesList />} />
            <Route path="files/add" element={<AddProfileFile />} />
            <Route path="query" element={<Query />} />
            <Route path="conversations" element={<Conversations />} />

            <Route path="c/:sessionId" element={<SessionDetail />} />
          </Route>
        </Route>
      </Route>

      <Route path="/share/:hash" element={<CollabChat />} />

      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
