import { PlansE } from "types/subscription";
import { IntegrationItemD } from "types/integration";

export const STORAGE_KEYS = {
  token: "token",
  user: "user",
  lastURL: "lastURL",
  querySessionData: "querySessionData",
  userId: "userId",
  prodURL: "productUrl",
};

export const APP_ID = "2";

type PlanD = {
  key: PlansE;
  name: string;
  planId: number;
  workspace: number;
  document: number;
  characters: number;
  credits: number;
  price: number;
  trail?: boolean;
};

export const PLANS: {
  [key in PlansE]: PlanD;
} = {
  qa_basic_trial: {
    key: PlansE.trial,
    name: "Trial",
    planId: 7,
    workspace: 1,
    document: 2,
    characters: 100000,
    credits: 30,
    price: 0,
    trail: true,
  },
  qa_starter_monthly: {
    key: PlansE.starter_monthly,
    name: "Starter",
    planId: 7,
    workspace: 1,
    document: 10,
    characters: 1000000,
    credits: 500,
    price: 9,
    trail: false,
  },
  qa_essential_monthly: {
    key: PlansE.essential_monthly,
    name: "Essential",
    planId: 8,
    workspace: 3,
    document: 30,
    characters: 3000000,
    credits: 3000,
    price: 29,
    trail: false,
  },
  qa_professional_monthly: {
    key: PlansE.professional_monthly,
    name: "Pro",
    planId: 9,
    workspace: 10,
    document: 150,
    characters: 10000000,
    credits: 10000,
    price: 99,
    trail: false,
  },
  qa_elite_monthly: {
    key: PlansE.elite_monthly,
    name: "Elite",
    planId: 10,
    workspace: Infinity,
    document: 500,
    characters: 100000000,
    credits: 50000,
    price: 499,
    trail: false,
  },
};

export const INTEGRATIONS: IntegrationItemD[] = [
  {
    id: 1,
    name: "Discord Bot",
    image: "https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/discord.svg",
    description: "",
    details: "",
    type: "other",
    color: "#5765F2",
  },
  {
    id: 2,
    name: "Slack Bot",
    image: "https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/slack.svg",
    description: "",
    details: "",
    type: "other",
    color: "#51104c",
  },
  // {
  //     id: 3,
  //     name: 'Chat Bot',
  //     image: 'https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/chatbot.svg',
  //     description: '',
  //     details: '',
  //     type: 'other',
  //     color: '#8803fc'
  // },
  {
    id: 7,
    name: "Telegram Bot",
    image: "https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/telegram.svg",
    description: "",
    details: "",
    type: "other",
    color: "#0088cc",
  },
  // {
  //   id: 4,
  //   name: "Zapier",
  //   image: "https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/zapier.svg",
  //   description: "",
  //   details: "",
  //   type: "other",
  //   color: "#e63900",
  // },
  // {
  //     id: 5,
  //     name: 'HubSpot',
  //     image: 'https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/hubspot.svg',
  //     description: '',
  //     details: '',
  //     type: 'other',
  //     color: '#F87520'
  // },
  // {
  //   id: 6,
  //   name: "API Integration",
  //   image: "https://s3.us-east-2.amazonaws.com/assets.yourgpt.ai/images/api-integration.svg",
  //   description: "",
  //   details: "",
  //   type: "other",
  //   color: "#00A0C8",
  // },
];

// export const STREAM_LINK = `//192.168.18.45:5001/api/v1/qamaster`;
export const STREAM_LINK = "https://gpt.yourgpt.ai/api/v1/qamaster";
