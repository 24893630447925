import React from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
export default function ZSkel() {
  return (
    <Root className="absolute top-0 left-0 h-full w-full z-2">
      <Skeleton height={"100%"} width={"100%"} />
    </Root>
  );
}

const Root = styled.div`
  .react-loading-skeleton {
    display: flex;
  }
`;
