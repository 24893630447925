import { createShareLinkSessionApi } from "network/api/workspace/session";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { ApiRes } from "types";
import { SessionD } from "types/session";
import Welcome from "./Welcome";
import { THEME } from "utils/theme";
import { IoClose } from "react-icons/io5";
import Logo from "assets/svg/qa-master-logo.svg";
import ChatPage from "components/ChatPageV2";

export default function CollabChat() {
  const { hash } = useParams();
  const [sessionDetail, setSessionDetail] = useState<null | SessionD>(null);
  // const [loading, setLoading] = useState(false);

  const createSession = async () => {
    try {
      if (!hash) {
        return;
      }
      // setLoading(true);
      const res = await createShareLinkSessionApi({ hash });
      // setLoading(false);
      if (res.type === ApiRes.SUCCESS) {
        setSessionDetail(res.data);
        localStorage.setItem(hash, JSON.stringify(res.data));
      }
    } catch (err) {
      // setLoading(false);
      console.error("Err", err);
    }
  };

  useEffect(() => {
    if (hash) {
      const st = localStorage.getItem(hash);
      if (st) {
        const val = JSON.parse(st);

        if (val) {
          setSessionDetail(val);
        }
      }
    }
  }, [hash]);

  const endSession = async () => {
    try {
      if (hash) {
        localStorage.removeItem(hash);
      }
      window.location.reload();
    } catch (err) {
      console.error("Err", err);
    }
  };

  return (
    <>
      {sessionDetail ? (
        <Root className="relative flex flex-col ">
          <Header className="flex items-center justify-between px-6">
            <div className="flex gap-3 items-center">
              <a
                href={"https://yourgpt.ai/qa-master"}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Logo}
                  style={{ height: 38, width: "auto" }}
                  alt="logo"
                />
              </a>
            </div>
            <div className="font-semibold px-3 rounded-lg py-2">
              {sessionDetail?.work_space?.name}
            </div>
            <button
              className="flex bg-red-50 text-red-500 items-center gap-2 py-2 px-4 text-sm ring-1 hover:ring-red-200 ring-transparent transition-all rounded-full"
              onClick={endSession}
            >
              Clear chat <IoClose />
            </button>
          </Header>
          <ChatPage sessionD={sessionDetail} place="shared" />
        </Root>
      ) : (
        <Welcome onStart={createSession} />
      )}
    </>
  );
}

const Root = styled.div`
  height: 100svh;
`;
const Header = styled.div`
  height: ${THEME.appbarHeight.workspace}px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
`;
