import { post } from "network";

export const getOrganisationApi = async ({ token, ...raw }: { token: string; limit: number; page: number; search?: string }) => {
  return post({
    route: "/api/v1/getMyOrganizations",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
