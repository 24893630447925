import LoadingDots from "components/LoadingDots";
import ActionBar from "components/chat/ActionBar";
import MessageItem from "components/chat/MessageItem";
import { useAuth } from "context/AuthContext";
import { createShareLinkSessionApi, getSessionDetailBySessionIdApi, getSessionMessagesBySessionIdApi, getSharedLinkMessagesBySessionIdApi, sessionMessageSeenApi } from "network/api/workspace/session";
import { useCallback, useEffect, useRef, useState } from "react";
import { ApiRes } from "types";
import { MessageD } from "types/chat";
import { useIntersectionObserver } from "usehooks-ts";
import { useParams } from "react-router-dom";
import { useWorkspace } from "context/WorkspaceContext";
import { SessionD } from "types/session";
import { STREAM_LINK } from "utils/constants";
import { getShareLinkApi } from "network/api/workspace/integration";
import styled from "styled-components";
import { ChatRoot } from "components/chat/styles";
import { THEME } from "utils/theme";
import InfoBox from "./InfoBox";

const LIMIT = 60;
const FOOTER_HEIGHT = 90;

export default function ChatPage({ sessionUid, place, sessionD, onSessionCreate }: { sessionUid?: string; place: "query" | "detail" | "shared"; sessionD?: SessionD | undefined; onSessionCreate?: (id: string) => any }) {
  const { token } = useAuth();
  const { projectUid } = useWorkspace();
  const [sessionDetail, setSessionDetail] = useState<SessionD | null>(sessionD || null);
  const [messages, setMessages] = useState([] as MessageD[]);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const observerTarget = useRef<HTMLDivElement>(null);
  const [thinking, setThinking] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestedQuery, setSuggestedQuery] = useState("");

  // ----------------------------------------------------------------
  // SESSION DETAIL
  // ----------------------------------------------------------------
  const [loadingSession, setLoadingSession] = useState(false);

  const fetchSessionDetail = useCallback(
    async (i: string) => {
      try {
        if (!i || !token) {
          return;
        }
        setLoadingSession(true);
        const res = await getSessionDetailBySessionIdApi({
          session_uid: i || "",
          token,
        });
        setLoadingSession(false);
        if (res.type === ApiRes.SUCCESS) {
          setSessionDetail(res.data);
          return res.data?.session_uid;
        }
      } catch (err) {
        setLoadingSession(false);
        console.error("Err", err);
      }
    },
    [token]
  );

  useEffect(() => {
    if (place === "query" && !sessionUid) {
      setMessages([]);
      setSessionDetail(null);
      setTotal(0);
    }
  }, [place, sessionUid]);

  // ----------------------------------------------------------------
  // DECIDER
  // ----------------------------------------------------------------
  useEffect(() => {
    if (sessionUid && !sessionDetail) {
      fetchSessionDetail(sessionUid);
    }
  }, [fetchSessionDetail, sessionUid, sessionDetail]);

  // ----------------------------------------------------------------
  // TRIGGER STREAM
  // ----------------------------------------------------------------
  useEffect(() => {
    let source: EventSource | null = null;
    if (!sessionDetail || !query.trim()) {
      return;
    }

    try {
      source = new EventSource(`${STREAM_LINK}?query=${query.trim()}&token=${sessionDetail.token}&stream=True`);

      source.onmessage = function (e) {
        setQuery("");

        console.log("e.data", e.data, "e.data.length", e.data.length);

        setMessages((s) => {
          const old = [...s];
          let data = "";

          if (e.data.length === 0) {
            data = old[0].message + "\n";
          } else {
            data = old[0].message + e.data;
          }

          data = data.replaceAll("[LINE_END]", "\n").replaceAll(":|", "\n|");

          old[0] = {
            ...old[0],
            loading: false,
            message: data,
            error: false,
          };
          return old;
        });
      };
      source.onerror = function (e) {
        setQuery("");
        source?.close();
        setMessages((s) => {
          const old = [...s];
          old[0] = {
            ...old[0],
            loading: false,
            generating: false,
          };
          return old;
        });
        setThinking(false);
      };
      source.addEventListener("response", (e: any) => {
        if (e.data) {
          setQuery("");

          try {
            const dat = JSON.parse(e.data);
            if (dat.id) {
              setMessages((s) => {
                const old = [...s];
                old[0] = {
                  ...old[0],
                  loading: false,
                  id: dat.id,
                };
                return old;
              });
            }
          } catch (err) {
            console.error("Err", err);
          }
        }
      });
      source.addEventListener("RXERROR", function (e) {
        setQuery("");
        setThinking(false);
        setMessages((s) => {
          const old = [...s];
          old[0] = {
            ...old[0],
            loading: false,
            generating: false,
            message: "Something went wrong try again ",
            error: true,
          };
          return old;
        });
      });
      source.addEventListener("end", function (e) {
        setQuery("");
        setThinking(false);
        setMessages((s) => {
          const old = [...s];
          old[0] = {
            ...old[0],
            loading: false,
            generating: false,
          };
          return old;
        });

        source?.close();
      });
    } catch (err) {
      setQuery("");

      setMessages((s) => {
        const old = [...s];
        old[0] = {
          ...old[0],
          loading: false,
          generating: false,
          message: "Something went wrong try again ",
          error: true,
        };
        return old;
      });
      setThinking(false);
    }
  }, [sessionDetail, query]);

  // ----------------------------------------------------------------
  // GET HASH
  // ----------------------------------------------------------------
  const [hash, setHash] = useState("");
  const { hash: routeHash } = useParams();

  useEffect(() => {
    const getHash = async () => {
      try {
        if (!token || !projectUid) {
          return;
        }
        const res = await getShareLinkApi({ project_uid: projectUid, token });
        if (res.type === ApiRes.SUCCESS) {
          setHash(res.data.hash);
        }
      } catch (err) {
        console.error("Err", err);
      }
    };

    getHash();
  }, [token, projectUid]);

  useEffect(() => {
    if (routeHash) {
      setHash(routeHash);
    }
  }, [routeHash]);

  // ----------------------------------------------------------------
  // FETCH MESSAGES
  // ----------------------------------------------------------------
  useEffect(() => {
    if (place !== "shared") {
      if (!token || !sessionDetail?.session_uid) return;
    } else {
      if (!hash || !sessionDetail?.session_uid) return;
    }

    const fetchMessages = async () => {
      try {
        if (page > 1) {
          setLoadMore(true);
        } else {
          setLoading(true);
        }

        const raw = {
          session_uid: sessionDetail?.session_uid,
          page: page,
          limit: LIMIT,
          orderBy: "desc",
        };

        let res: any;

        if (place === "shared") {
          res = await getSharedLinkMessagesBySessionIdApi({
            ...raw,
            hash,
            session_uid: sessionDetail?.session_uid,
          });
        } else {
          res = await getSessionMessagesBySessionIdApi({ token, raw });
        }

        if (token) {
          await sessionMessageSeenApi({
            token,
            session_uid: sessionDetail?.session_uid,
          });
        }

        if (res.type === ApiRes.SUCCESS && res.data?.length > 0) {
          if (page === 1) {
            setMessages(res?.data);
          } else {
            setMessages((prev) => [...prev, ...res?.data]);
          }
          setTotal(res?.total);
        }

        setLoadMore(false);
        setLoading(false);
      } catch (err) {
        setLoadMore(false);
        setLoading(false);
        console.error("Err", err);
      }
    };

    fetchMessages();
  }, [token, sessionDetail, page, hash, place]);
  // }, [page, token, sessionUid, hash, place, sessionDetail?.session_uid]);

  // ----------------------------------------------------------------
  // SCROLL STUFF
  // ----------------------------------------------------------------
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [scrollingEnabled, setScrollingEnabled] = useState(false);
  const bottomEntry = useIntersectionObserver(bottomRef, {});
  const bottomVisible = !!bottomEntry?.isIntersecting;

  const scrollToBottom = useCallback(
    (force = false, smooth = true) => {
      if (force) {
        bottomRef.current?.scrollIntoView({
          behavior: smooth ? "smooth" : "auto",
        });
      } else {
        if (scrollingEnabled) {
          bottomRef.current?.scrollIntoView({
            behavior: smooth ? "smooth" : "auto",
          });
        } else {
        }
      }
    },
    [scrollingEnabled]
  );

  const scrollToView = useCallback((node: any) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  useEffect(() => {
    if (messages.length === 0 || messages.length >= total) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((p) => p + 1);
        }
      },
      { threshold: 0.1 }
    );

    const observerCurrent = observerTarget.current;
    if (observerCurrent) {
      observer.observe(observerCurrent);
    }

    return () => {
      if (observerCurrent) {
        observer.unobserve(observerCurrent);
      }
    };
  }, [observerTarget, messages, total]);

  useEffect(() => {
    if (bottomVisible) {
      if (messages?.length > 0) {
        setScrollingEnabled(true);
      }
    } else {
      setScrollingEnabled(false);
    }
  }, [bottomVisible, messages]);

  useEffect(() => {
    scrollToBottom(false, false);
  }, [messages, scrollToBottom]);

  // ----------------------------------------------------------------
  // SEND MESSAGES
  // ----------------------------------------------------------------
  const onSend = async (text: any) => {
    try {
      scrollToBottom(true);
      setScrollingEnabled(true);

      if (thinking || [...messages].pop()?.loading) {
        return;
      }

      setQuery(text);
      setTotal((t) => t + 2);

      setMessages((s) => [
        {
          id: Date.now() + 1,
          send_by: "assistant",
          loading: true,
          message: "",
          createdAt: Date.now(),
          generating: true,
        },
        {
          id: Date.now(),
          createdAt: Date.now(),
          message: text,
          send_by: "user",
        },
        ...s,
      ]);
      setThinking(true);

      if (!sessionDetail && hash) {
        const res = await createShareLinkSessionApi({ hash });
        if (res.type === ApiRes.SUCCESS) {
          setSessionDetail(res.data);
          if (onSessionCreate) {
            onSessionCreate(res.data.session_uid);
          }
        }
      }
    } catch (err) {
      console.error("Err", err);
    }
  };

  return (
    <Root>
      <div
        className="flex flex-col-reverse overflow-y-auto"
        style={{
          height: `calc(100vh - ${THEME.appbarHeight.workspace}px - ${FOOTER_HEIGHT}px)`,
        }}
      >
        <div ref={bottomRef} />

        {place === "query" && (
          <>
            {messages.length === 0 && !sessionDetail && !sessionUid ? (
              <InfoBox
                onQuery={(t) => {
                  setSuggestedQuery(t);
                }}
              />
            ) : null}
          </>
        )}

        {(loading && messages.length === 0) || loadingSession ? (
          <div className="flex-1 justify-center flex items-center text-blue-500">
            <LoadingDots />
          </div>
        ) : (
          messages.map((message, index) => (
            <div key={index} ref={index === 0 || index === messages.length - LIMIT ? scrollToView : null} style={{ paddingBottom: index === 0 ? 32 : 0 }}>
              <MessageItem {...message} onSave={() => {}} sessionUid={sessionDetail?.session_uid || sessionUid || ""} />
            </div>
          ))
        )}

        <div ref={observerTarget}>
          {loadMore && (
            <div className="flex items-center justify-center p-10">
              <LoadingDots />
            </div>
          )}
        </div>
      </div>
      <footer style={{ height: FOOTER_HEIGHT }}>
        <ActionBar onMessage={onSend} message={suggestedQuery} />
      </footer>
    </Root>
  );
}

const Root = styled(ChatRoot)``;
