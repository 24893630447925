import React from "react";
import { ReactComponent as Bulb } from "assets/svg/bulb.svg";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { useWorkspace } from "context/WorkspaceContext";
import { BsFillArrowUpRightCircleFill, BsFillCheckCircleFill, BsFillKeyboardFill } from "react-icons/bs";

export default function InfoBox({ onQuery }: { onQuery: (s: string) => any }) {
  const { projectUid } = useWorkspace();

  return (
    <div className="flex-1 flex justify-center items-center mt-20">
      <div className="bg-gray-100 w-[90%] max-w-[600px] rounded-3xl p-6 py-8 -mt-12 flex flex-col justify-center">
        <Bulb className="h-16 mb-2" />

        <p className="mb-4 text-lg font-semibold text-center">Ask question to your trained QA Master</p>

        <div className="flex mb-6 flex-col gap-2">
          {[
            { label: "Get a Summary of the document", value: "Give me a summary of [file_name]" },
            { label: "Get Top key points from a document", value: "Breakdown Top key points from [file_name] in bullet format" },
            { label: "Get Top Questions & Answers from a document", value: "Breakdown Top Questions & Answers from [file_name]" },
            { label: "Query your whole knowledgebase", value: "Tell me about this {concept}" },
          ].map((i) => {
            return (
              <div
                key={i.value}
                className="flex gap-2 items-start text-gray-500  hover:text-gray-900 transition-all t cursor-pointer bg-gray-200 rounded-lg px-4 py-2"
                onClick={() => {
                  onQuery(i.value);
                }}
              >
                <BsFillCheckCircleFill className="text-green-600 mt-1" />
                <div>{i.label}</div>
                <div className="ml-auto ">
                  <button>
                    <BsFillKeyboardFill size={22} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex gap-2 justify-center flex-wrap">
          <Link to={`/workspace/${projectUid}/files`}>
            <Button>Train QA Master</Button>
          </Link>
          <Link to={`/workspace/${projectUid}/conversations`}>
            <Button>View old conversation</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
