import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { STORAGE_KEYS } from "utils/constants";
import Cookies from "js-cookie";

const AuthContext = createContext({} as AuthContextD);

declare const window: any;

export default function AuthProvider({ children }: any) {
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const logout = useCallback(async () => {
    setToken("");
    localStorage.removeItem(STORAGE_KEYS.token);
    localStorage.removeItem(STORAGE_KEYS.user);
    Cookies.remove("token", { domain: "yourgpt.ai" });
    window.location.href = "https://app.yourgpt.ai/login";
  }, []);

  useEffect(() => {
    //DONT TOUCH IN
    if (typeof process.env.REACT_APP_DUMMY_TOKEN !== "undefined" && process.env.REACT_APP_DUMMY_TOKEN) {
      setToken(process.env.REACT_APP_DUMMY_TOKEN);
      return;
    }

    if (Cookies.get(STORAGE_KEYS.token)) {
      setToken(Cookies.get(STORAGE_KEYS.token)!);
      setUserId(Cookies.get(STORAGE_KEYS.userId)!);
    } else {
      Cookies.set(STORAGE_KEYS.prodURL, "https://qamaster.yourgpt.ai", {
        domain: "yourgpt.ai",
        expires: 365,
      });
      window.location.href = "https://app.yourgpt.ai/login";
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (!userId) return;

    const clarity = window.clarity;
    if (clarity) {
      clarity("set", "user", userId);
    }
  }, [userId]);

  return (
    <AuthContext.Provider
      value={{
        token,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

type AuthContextD = {
  token: string;
  logout: () => any;
};
