import React from "react";
import styled from "styled-components";

export default function LoadingDots({ light = false }: { light?: boolean }) {
  return (
    <Root light={light}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </Root>
  );
}

const Root = styled.div<{ light?: boolean }>`
  text-align: center;
  display: flex;
  gap: 4px;

  .dot {
    animation: dotKeyframes 1.5s infinite ease-in-out;
    background-color: currentColor;
    border-radius: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }

  @keyframes dotKeyframes {
    0% {
      opacity: 0.4;
      transform: scale(1, 1);
    }

    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }

    100% {
      opacity: 0.4;
      transform: scale(1, 1);
    }
  }
`;
