import React from "react";
import Chatbox from "./chatbox";

export default function Query() {
  return (
    <div>
      <Chatbox />
    </div>
  );
}
