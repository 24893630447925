import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { log, logErr } from "utils/helpers";
import { ReactComponent as DiscordLogo } from "assets/svg/discord.svg";
import { Button, CircularProgress } from "@mui/joy";
import { Link } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { toast } from "react-hot-toast";
import { ApiRes } from "types";
import { enableDiscordApi } from "network/api/workspace/integration";
import { STORAGE_KEYS } from "utils/constants";

export default function DiscordIntegrationStatus() {
  const [sParams]: any = useSearchParams();

  const [params, setParams] = useState<any>({});

  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setParams(Object.fromEntries([...sParams]));
  }, [sParams]);

  useEffect(() => {
    if (params?.error) {
      toast.error(params?.error_description);

      if (localStorage.getItem(STORAGE_KEYS.lastURL)) {
        window.location.href =
          localStorage.getItem(STORAGE_KEYS.lastURL) ||
          "https://qamaster.yourgpt.ai";
      } else {
        navigate("/");
      }
    }
  }, [params, navigate]);

  useEffect(() => {
    const enable = async () => {
      try {
        setLoading(true);
        const res = await enableDiscordApi({
          token,
          project_uid: params?.state,
          guild_id: params.guild_id,
          permission: params?.permissions,
        });
        setLoading(false);
        log(res);
        if (res.type === ApiRes.SUCCESS) {
          toast.success("Discord bot added successfully!");
          if (localStorage.getItem(STORAGE_KEYS.lastURL)) {
            window.location.href =
              localStorage.getItem(STORAGE_KEYS.lastURL) ||
              "https://qamaster.yourgpt.ai";
          } else {
            navigate("/");
          }
          // navigate(localStorage.getItem(STORAGE_KEYS.lastURL) || "/");
        } else {
          setError(res.message);
        }
      } catch (err) {
        setError("Something went wrong");
        setLoading(false);
        logErr("Err", err);
      }
    };

    if (params?.guild_id && params?.state && token) {
      enable();
    }
  }, [token, params, navigate]);

  return (
    <div className="h-screen flex place-items-center">
      <div className="shadow-md w-[500px] max-w-[90%] mx-auto p-8 rounded-lg">
        <div className="flex items-center gap-2 justify-center">
          <DiscordLogo className="w-auto h-8" />
          <div className="font-bold">Discord</div>
        </div>

        {error && (
          <div className="text-red-500 py-6 font-semibold text-center">
            {error}
          </div>
        )}

        {params?.error ? (
          <div className="py-4">
            <div className="text-red-500 text-center mb-6">
              <div className="  font-bold text-xl mb-2">{params?.error}</div>
              <div className="">{params?.error_description}</div>
            </div>
            <div className="flex gap-2 justify-center">
              {localStorage.getItem(STORAGE_KEYS.lastURL) && (
                <Link to={localStorage.getItem(STORAGE_KEYS.lastURL) || "/"}>
                  <Button variant="outlined">Go to project</Button>
                </Link>
              )}
              <Link to={"/dashboard"}>
                <Button variant="soft">Open console</Button>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            {loading && (
              <div className="flex gap-2 items-center justify-center py-6">
                <CircularProgress size="sm" />
                <div>Configuring Discord Bot...</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
