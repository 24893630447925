import { post } from "network";
import { SortD } from "types";

export const getMyWorkspacesApi = async ({ token, ...raw }: { token: string; page?: number; limit?: number; orderBy?: SortD; search?: string }) => {
  return post({
    route: "/qa-master/v1/getMyWorkSpaces",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const deleteWorkspaceApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/deleteWorkSpace",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const createWorkspaceApi = async ({ token, ...raw }: { token: string; name: string }) => {
  return post({
    route: "/qa-master/v1/createWorkSpace",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const updateWorkspaceApi = async ({ token, ...raw }: { token: string; name: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/updateWorkSpace",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const getWorkspaceDetailApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/getWorkSpaceDetail",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
