import { CircularProgress } from "@mui/joy";
import React from "react";

export default function OverlayLoader({ dark = false }) {
  return (
    <div className={`flex absolute h-full w-full items-center justify-center  z-10 ${dark ? "bg-black/60" : "bg-white/70"}`}>
      <CircularProgress size="sm" />
    </div>
  );
}
