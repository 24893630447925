import Sidebar from "./Sidebar";
import { Outlet } from "react-router";
import { THEME } from "utils/theme";
import Appbar from "../Appbar";
import { useWorkspace } from "context/WorkspaceContext";
import PlansModal from "components/Modals/PlansModal";
import { useApp } from "context/AppContext";

export default function WorkspaceLayout() {
  const { projectDetail } = useWorkspace();
  const { trialExpired } = useApp();

  return (
    <div className="flex">
      <Sidebar />
      <div
        className="flex flex-col flex-1"
        style={{ marginLeft: THEME.sidebarWidth }}
      >
        <div className="sticky top-0">
          <Appbar workspace title={projectDetail?.name} />
        </div>
        <main>
          <Outlet />
        </main>
      </div>

      {trialExpired && (
        <PlansModal
          open={true}
          onClose={() => {}}
          message="Your free trial has expired please select a plan"
        />
      )}
    </div>
  );
}
