import { Modal, ModalClose } from "@mui/joy";

export default function Overlay({
  children,
  open,
  onClose,
}: {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      role="none"
      disableAutoFocus
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        outline: "none",
      }}
    >
      <div className="relative">
        <ModalClose
          variant="plain"
          sx={{ zIndex: 10, color: "#fff", right: 16, top: 10 }}
        />
        {children}
      </div>
    </Modal>
  );
}
