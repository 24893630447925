import { Button, Input, Modal, ModalClose, Sheet } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { logErr } from "utils/helpers";
import styled from "@emotion/styled";
import { CgSpinner } from "react-icons/cg";
import ConfirmModal from "components/ConfirmModal";
import { useWorkspace } from "context/WorkspaceContext";
import { IntegrationItemD } from "types/integration";
import { IntegrationId } from "types/enum";
import { STORAGE_KEYS } from "utils/constants";
import { disableIntegrationApi, enableTelegramApi } from "network/api/workspace/integration";
import { ApiRes } from "types";

const MODAL_HEIGHT = 450;

export default function IntegrationCard(
  i: IntegrationItemD & {
    type: "my" | "other";
    onEnable?: (id: IntegrationId) => void;
    onDisable?: (id: IntegrationId) => any;
  }
) {
  const { projectUid } = useWorkspace();
  const { token } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [enabling, setEnabling] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [open, setOpen] = useState(false);
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [teleCurrentStep, setTeleCurrentStep] = useState(1);
  const [telegramUserId, setTelegramUserId] = useState<any>("");

  const enableDiscord = async () => {
    localStorage.setItem(STORAGE_KEYS.lastURL, window.location.href + "?type=discord");
    window.location.href = `https://discord.com/oauth2/authorize?client_id=1115187446708178975&redirect_uri=https://qamaster.yourgpt.ai/integration/discord/status&response_type=code&permissions=397284616192&scope=bot&state=${projectUid}`;
  };
  const enableSlack = async () => {
    localStorage.setItem(STORAGE_KEYS.lastURL, window.location.href + "?type=slack");
    window.location.href = `https://slack.com/oauth/v2/authorize?client_id=4950299087701.5402085103584&scope=channels:history,chat:write,chat:write.public,commands,im:history,im:read,im:write,channels:join&user_scope=channels:read&state=${projectUid}
    `;
  };
  const onEnable = async () => {
    try {
      if (!projectUid) return;

      let res: any;
      setEnabling(true);
      switch (i.id) {
        case IntegrationId.discord:
          enableDiscord();
          break;
        case IntegrationId.slack:
          enableSlack();
          break;
        case IntegrationId.telegram:
          res = await enableTelegramApi({
            token,
            project_uid: projectUid,
            telegram_user_id: telegramUserId,
          });
          setShowTelegramModal(false);
          setTeleCurrentStep(1);
          break;
        default:
          return;
      }
      setEnabling(false);
      if (res.type === ApiRes.SUCCESS) {
        toast.success(`${i.name} integration is enabled`, {});
        if (i.onEnable) {
          i.onEnable(i.id);
        }
      }
    } catch (err) {
      setEnabling(false);
      logErr("Err", err);
    }
  };
  const goToSettings = () => {
    if (i.type === "my") {
      switch (i.id) {
        case IntegrationId.chatbot:
          navigate(pathname + "/chatbot");
          break;
        default:
          return;
      }
    }
  };
  const onDisable = async () => {
    try {
      if (!projectUid) return;
      setDisabling(true);
      const res = await disableIntegrationApi({
        project_uid: projectUid,
        token,
        integration_id: i.id.toString(),
      });

      if (res.type === ApiRes.SUCCESS) {
        if (i.onDisable) {
          i.onDisable(i.id);
        }
      }
      if (res.type === ApiRes.ERROR) {
        toast.error(res?.message);
      }

      setDisabling(false);
      setDisableModalOpen(false);
    } catch (err: any) {
      setDisabling(false);
      setDisableModalOpen(false);
      toast.error(err?.message);
      logErr("Err", err);
    }
  };
  const openDiscordModal = () => {
    searchParams.set("type", "discord");
    setSearchParams(searchParams);
  };
  const openSlackModal = () => {
    searchParams.set("type", "slack");
    setSearchParams(searchParams);
  };

  return (
    <Root className="shadow-sm rounded-lg px-4 py-4 flex flex-col gap-4 hover:shadow-md transition-all ">
      <div className="flex gap-2 items-center">
        <img src={i.image} alt={i.name} />
        <h3 className="font-semibold text-lg">{i.name}</h3>
      </div>

      <div>{i.description}</div>

      <div className="flex justify-between items-center">
        {i.type === "other" && i.id !== IntegrationId.zapier && i.id !== IntegrationId.apiIntegration && i.id !== IntegrationId.hubSpot && i.id !== IntegrationId.telegram && (
          <Button variant="outlined" loading={enabling} size="sm" onClick={onEnable}>
            Enable
          </Button>
        )}

        {i.type === "other" && i.id === IntegrationId.telegram && (
          <Button variant="outlined" size="sm" onClick={() => setShowTelegramModal(true)}>
            Enable
          </Button>
        )}

        {i.type === "my" && i.id === IntegrationId.chatbot && (
          <Button variant="solid" size="sm" color="primary" onClick={goToSettings}>
            Go to Settings
          </Button>
        )}

        {i.type === "my" && i.id !== IntegrationId.chatbot && (
          <Button color="danger" variant="outlined" size="sm" onClick={() => setDisableModalOpen(true)}>
            Disable
          </Button>
        )}

        {(i.id === IntegrationId.zapier || i.id === IntegrationId.apiIntegration || i.id === IntegrationId.hubSpot) && i.type === "other" && (
          <div className="ext-sm font-semibold mt-auto text-gray-600 hover:text-gray-700 duration-200">
            <Button variant="outlined" color="neutral" size="sm">
              <a href="https://yourgpt.ai/contact" target="_blank" rel="noreferrer">
                <span className="flex items-center justify-center gap-1 whitespace-nowrap opacity-80">Talk to us</span>
              </a>
            </Button>
          </div>
        )}

        {i.id !== IntegrationId.telegram && (
          <div
            onClick={() => {
              if (i.id === IntegrationId.discord) {
                openDiscordModal();
              }
              if (i.id === IntegrationId.slack) {
                openSlackModal();
              }
              if (i.id === IntegrationId.telegram) {
                setOpen(true);
              }
            }}
            className="font-semibold text-sm cursor-pointer border-b-[1px] border-[transparent] hover:border-[#0007] duration-500 opacity-70"
          >
            Learn more
          </div>
        )}
      </div>

      {/* CONFIRM DISABLE */}
      <ConfirmModal
        open={disableModalOpen}
        onClose={() => setDisableModalOpen(false)}
        danger
        confirmTitle="Disable"
        loading={disabling}
        onConfirm={() => onDisable()}
        desc={`Are you sure you want to disable ${i.name} integration`}
        title="Disable Integration"
      />

      {/* DETAILS MODAL */}
      <Modal
        aria-labelledby="close-modal-title"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: "sm",
            maxWidth: "90%",
            width: 700,
            minHeight: MODAL_HEIGHT,
            position: "relative",
            zIndex: 10,
            overflowY: "auto",
          }}
        >
          <ModalClose variant="outlined" />
          <div className={`flex items-center h-full`} style={{ minHeight: MODAL_HEIGHT }}>
            <div className={`w-1/2 h-full flex flex-col items-center justify-center gap-5`} style={{ minHeight: MODAL_HEIGHT, background: i.color }}>
              <div className="bg-white rounded-lg h-[100px] w-fit p-2">
                <img src={i.image} alt={i.name} className="h-full w-full" />
              </div>
              <div className="text-2xl font-semibold text-white text-center">
                Integrate
                <br />
                {i.name}
              </div>
              {i.type === "other" && i.id !== IntegrationId.zapier && i.id !== IntegrationId.apiIntegration && i.id !== IntegrationId.hubSpot && (
                <ModalBtn onClick={onEnable}>{enabling ? <CgSpinner className="animate-spin" /> : "Enable " + i.name}</ModalBtn>
              )}
              {i.type === "my" && i.id === IntegrationId.chatbot && <ModalBtn onClick={goToSettings}>{i.name} Settings</ModalBtn>}
              {(i.id === IntegrationId.zapier || i.id === IntegrationId.apiIntegration || i.id === IntegrationId.hubSpot) && i.type === "other" && (
                <div className="text-sm font-semibold text-gray-600 hover:text-gray-700 duration-200">
                  <ModalBtn>
                    <a href="https://yourgpt.ai/contact" target="_blank" rel="noreferrer">
                      <span className="flex items-center justify-center gap-1 whitespace-nowrap opacity-80">Talk to us</span>
                    </a>
                  </ModalBtn>
                </div>
              )}
            </div>
            <div className="w-1/2 h-full p-10 text-center">{i.details}</div>
          </div>
        </Sheet>
      </Modal>

      {/* TELEGRAM MODAL */}
      <Modal
        aria-labelledby="close-modal-title"
        open={showTelegramModal}
        onClose={() => {
          setShowTelegramModal(false);
          setTeleCurrentStep(1);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: "sm",
            maxWidth: "90%",
            width: 700,
            minHeight: MODAL_HEIGHT,
            position: "relative",
            zIndex: 10,
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ModalClose variant="outlined" />
          <div className="my-auto mx-auto">
            {teleCurrentStep === 1 && (
              <TeleStepOne>
                <div>
                  <div className="font-semibold text-2xl">Step 1</div>
                  <div>Scan the qr code to add bot to your telegram</div>
                </div>
                <img src={require("../../../assets/images/telegramQr.png")} alt="qr code" className="h-[220px] mt-5" />

                <div className="flex items-center justify-center mt-5">
                  <Button onClick={() => setTeleCurrentStep(2)}>Next</Button>
                </div>
              </TeleStepOne>
            )}

            {teleCurrentStep === 2 && (
              <TeleStepTwo>
                <div>
                  <div className="font-semibold text-2xl">Step 2</div>
                  <div>
                    Enter command <span className="bg-slate-200 text-xl font-medium">/start</span> in telegram QA Master chat
                  </div>
                  <div>to receive your user id</div>
                </div>

                <div className="flex items-center justify-center mt-5">
                  <Button onClick={() => setTeleCurrentStep(3)}>Next</Button>
                  <Button variant="plain" onClick={() => setTeleCurrentStep(1)}>
                    Back
                  </Button>
                </div>
              </TeleStepTwo>
            )}

            {teleCurrentStep === 3 && (
              <TeleStepThree>
                <div className="font-semibold text-2xl">Step 3</div>
                <div>Enter your user id to enable telegram bot</div>
                <form className="mt-5">
                  {/* <label>Enter your User id</label> */}
                  <Input type="text" placeholder="User id" value={telegramUserId} onChange={(e) => setTelegramUserId(e.target.value)} />
                  <div className="flex items-center justify-center mt-5">
                    <Button disabled={telegramUserId.trim() === ""} onClick={onEnable} loading={enabling}>
                      Enable
                    </Button>
                    <Button variant="plain" onClick={() => setTeleCurrentStep(2)}>
                      Back
                    </Button>
                  </div>
                </form>
              </TeleStepThree>
            )}
          </div>
        </Sheet>
      </Modal>
    </Root>
  );
}

const Root = styled.div`
  img {
    height: 40px;
  }
`;
const ModalBtn = styled.button`
  font-family: inherit;
  border-radius: 5px;
  border: 0.5px solid rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.15);
  padding: 0.5em 1em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;
const TeleStepOne = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
`;
const TeleStepTwo = styled.div``;
const TeleStepThree = styled.div``;
