import { useEffect } from "react";
import styled from "styled-components";
import { useApp } from "context/AppContext";
import { MdDownload } from "react-icons/md";
import { formatDateTime, formatNumberShort, getDaysUntilDate } from "utils/helpers";
import { tv } from "tailwind-variants";
import { PlansE, SubscriptionStatus } from "types/subscription";
import { PLANS } from "utils/constants";
import { Button } from "@mui/joy";

export default function Billing() {
  const { activePlan, fetchPlan, openUpgrade } = useApp();

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  const creditsLeft = Number(activePlan?.usage.credits.limit || 0) - Number(activePlan?.usage.credits.usage || 0);
  const workspaceLeft = Number(activePlan?.usage.workspace.limit || 0) - Number(activePlan?.usage.workspace.usage || 0);
  const documentLeft = Number(activePlan?.usage.document.limit || 0) - Number(activePlan?.usage.document.usage || 0);
  const characterLeft = Number(activePlan?.usage.characters.limit || 0) - Number(activePlan?.usage.characters.usage || 0);

  return (
    <Root className="max-w-[90%] md:max-w-[80vw] mx-auto py-10">
      <h2 className="font-semibold text-xl mb-8">Billing Details</h2>

      {activePlan && (
        <>
          {/* Current Plan */}
          <>
            <h3 className="font-medium mb-2 ">Current Plan:</h3>
            <div className={`border rounded-md p-4 ${activePlan?.status === SubscriptionStatus.trialing ? "border-yellow-500 bg-yellow-50" : "bg-blue-50 border-blue-500"}`}>
              <h4 className={`font-semibold mb-2 ${activePlan?.status === SubscriptionStatus.trialing ? "text-yellow-500" : "text-blue-500"}`}>
                {activePlan?.status === SubscriptionStatus.trialing ? "Trial Plan" : PLANS[activePlan.plan]?.name}
              </h4>
              {activePlan?.status === SubscriptionStatus.trialing && (
                <div className="text-lg mb-4">
                  {Number(getDaysUntilDate(activePlan?.trail_plan?.expiry_date)) > 0
                    ? getDaysUntilDate(activePlan?.trail_plan?.expiry_date) + "Days Trial Left"
                    : Number(getDaysUntilDate(activePlan?.trail_plan?.expiry_date)) === 0
                    ? "Your Trail expires today"
                    : Number(getDaysUntilDate(activePlan?.trail_plan?.expiry_date)) < 0
                    ? "Your trail has expired"
                    : ""}
                </div>
              )}
              Trail {Number(getDaysUntilDate(activePlan?.trail_plan?.expiry_date)) < 0 ? "expired" : "expiring"} on :{" "}
              <span className="font-semibold "> {activePlan?.status === SubscriptionStatus.trialing ? formatDateTime(activePlan?.trail_plan?.expiry_date) : formatDateTime(activePlan?.next_billing_cycle?.period_end)}</span>
            </div>
          </>

          {/* Usage */}
          <div className="mt-8">
            <h3 className="text-lg font-semibold mb-4">Usage:</h3>

            <div className="flex flex-col gap-2">
              <div className={field()}>
                <div className={fieldLabel()}>Workspaces:</div>
                <div
                  className={fieldVal({
                    class: workspaceLeft === 0 ? "text-red-500" : "",
                  })}
                >
                  {formatNumberShort(workspaceLeft)} left <span className="font-normal text-gray-500"> / {formatNumberShort(Number(activePlan?.usage.workspace.limit))}</span>
                </div>
              </div>
              <div className={field()}>
                <div className={fieldLabel()}>Credits:</div>
                <div
                  className={fieldVal({
                    class: creditsLeft === 0 ? "text-red-500" : "",
                  })}
                >
                  {formatNumberShort(Number(activePlan?.usage.credits.limit || 0) - Number(activePlan?.usage.credits.usage))} left{" "}
                  <span className="font-normal text-gray-500"> / {formatNumberShort(Number(activePlan?.usage.credits.limit))}</span>
                </div>
              </div>

              <div className={field()}>
                <div className={fieldLabel()}>Documents ({formatNumberShort(characterLeft)} characters):</div>
                <div
                  className={fieldVal({
                    class: documentLeft === 0 ? "text-red-500" : "",
                  })}
                >
                  {Number(activePlan?.usage.document.limit || 0) - Number(activePlan?.usage.document.usage)} left
                  <span className="font-normal text-gray-500"> / {activePlan?.usage.document.limit}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Invoice */}
          <div className="mt-8">
            {activePlan?.paid_invoice[0]?.invoice_pdf && (
              <Button variant="soft">
                <a href={activePlan?.paid_invoice[0]?.invoice_pdf} className="flex items-center gap-2">
                  <MdDownload className="text-xl" />
                  Download Invoice
                </a>
              </Button>
            )}
          </div>

          {/* upgrade */}
          {/* {activePlan && ( */}
          <>
            {
              // activePlan?.status === SubscriptionStatus.active &&
              activePlan?.plan !== PlansE.elite_monthly && (
                <div className="mt-10">
                  <div className="mb-2 font-semibold">Uncover a multitude of benefits by exploring alternative plans</div>
                  <Button onClick={() => openUpgrade()}>Explore Plans</Button>
                </div>
              )
            }
          </>
          {/* )} */}
        </>
      )}
    </Root>
  );
}

const Root = styled.div``;

const field = tv({
  base: "grid grid-cols-2 max-w-[600px] items-center",
});
const fieldLabel = tv({
  base: "",
});
const fieldVal = tv({
  base: "font-semibold",
});
