import React from "react";
import { THEME } from "utils/theme";
import { FaArrowLeft, FaDatabase, FaFile, FaLink, FaPlayCircle, FaPlaystation, FaShare, FaUser } from "react-icons/fa";
import { AiFillHome, AiFillMessage } from "react-icons/ai";
import { RiArrowGoBackFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";
import WorkSpaceDropdown from "./WorkSpaceDropdown";
import { FiArrowLeft } from "react-icons/fi";

const LINKS = [
  {
    id: 1,
    link: "dashboard",
    icon: <AiFillHome />,
    label: "Dashboard",
  },
  {
    id: 4,
    link: "query",
    icon: <FaPlayCircle />,
    label: "Query",
  },
  {
    id: 3,
    link: "conversations",
    icon: <AiFillMessage />,
    label: "Conversations",
  },

  {
    id: 2,
    link: "files",
    icon: <FaFile />,
    label: "Files",
  },

  {
    id: 5,
    link: "integration",
    icon: <FaShare />,
    label: "Integration",
  },
];

export default function Sidebar() {
  return (
    <div style={{ width: THEME.sidebarWidth, position: "fixed", left: 0, top: 0 }} className="bg-[#102233] border-r border-gray-200 h-screen">
      <Link to="/" className="py-8 flex gap-2 font-semibold text-white mx-3 items-center relative">
        <FiArrowLeft className="" />
        QA Master
      </Link>

      <div className=" border-b border-white/30 pb-4 mb-4 mx-3">
        <WorkSpaceDropdown />
      </div>

      <div className="px-3 flex flex-col gap-2">
        {LINKS.map((i) => {
          return <MenuItem key={i.id} {...i} />;
        })}
      </div>
    </div>
  );
}
