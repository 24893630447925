import { post } from "network";

export const getActivePlanApi = async ({ token, ...raw }: { token: string; id: string; type: "project" | "organization" }) => {
  return post({
    route: "/api/v1/getActivePlanDetail",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const createSubscriptionApi = async ({ token, ...raw }: { token: string; id: string; plan: string }) => {
  return post({
    route: "/api/v1/createSubscription",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const updateSubscriptionApi = async ({ token, ...raw }: { token: string; id: string; plan: string }) => {
  return post({
    route: "/api/v1/updateSubscription",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
