import Button from "components/Button";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import Logo from "assets/svg/qa-master-logo.svg";

export default function Welcome({ onStart }: { onStart: () => any }) {
  return (
    <div className="flex justify-center items-center min-h-screen w-full bg-gray-100">
      <div className=" bg-white shadow-sm rounded-xl w-[600px] max-w-[90%] px-4 py-8 flex flex-col justify-center items-center gap-4">
        <div className="flex flex-col justify-center items-center gap-3 mb-4">
          <div className="">
            <img src={Logo} style={{ height: 48, width: "auto" }} />
          </div>
          <p className="text-gray-500">Click the button to start chating</p>
        </div>

        <Button color="dark" onClick={onStart}>
          Start Chat <FaArrowRight />
        </Button>
      </div>
    </div>
  );
}
