import { getWorkspaceDetailApi } from "network/api/workspace";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "./AuthContext";
import { ApiRes } from "types";
import { ProjectDetailD } from "types/project";

const WorkspaceContext = createContext({} as WorkspaceContextD);

export default function WorkspaceProvider({ children }: any) {
  const params = useParams();
  const [projectUid, setProjectUid] = useState<string | null>(null);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [projectDetail, setProjectDetail] = useState<ProjectDetailD | null>(null);
  const { token, logout } = useAuth();

  useEffect(() => {
    setProjectUid(params.projectUid || null);
  }, [params]);

  const fetchWorkspace = useCallback(async () => {
    try {
      if (!token || !projectUid) {
        return;
      }

      setLoadingDetail(true);
      const res = await getWorkspaceDetailApi({
        token,
        project_uid: projectUid,
      });
      setLoadingDetail(false);
      if (res.type === ApiRes.SUCCESS) {
        setProjectDetail(res.data);
      }
      if (res.type === ApiRes.ERROR && res?.code === 401) {
        logout();
      }
    } catch (err) {
      setLoadingDetail(false);
      console.error("Err", err);
    }
  }, [token, projectUid, logout]);

  useEffect(() => {
    fetchWorkspace();
  }, [fetchWorkspace]);

  return <WorkspaceContext.Provider value={{ projectUid, projectDetail, loadingDetail }}>{children}</WorkspaceContext.Provider>;
}

export function useWorkspace() {
  return useContext(WorkspaceContext);
}

type WorkspaceContextD = {
  projectUid: string | null;
  projectDetail: ProjectDetailD | null;
  loadingDetail: boolean;
};
