import styled from "styled-components";
import Overlay from "./Overlay";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ReactComponent as SlackLogo } from "../../assets/svg/slack.svg";

export default function SlackModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Overlay open={open} onClose={onClose}>
      <Root>
        <div className="px-6 md:px-12 lg:px-16 py-8 bg-[#1B1B1B] text-white flex items-center gap-5">
          <SlackLogo className="w-auto h-10" />
          <div className="font-semibold text-xl">Slack Integration</div>
        </div>

        <div className="px-6 md:px-12 lg:px-16 py-8 flex flex-col gap-5">
          <h3 className="font-semibold text-xl mb-2">How to use QA Master Slack Bot?</h3>

          <div className="flex items-center gap-5 font-semibold">
            <div className="bg-[#1B1B1B] flex items-center text-white text-2xl justify-center p-2 rounded-full">
              <MdKeyboardArrowRight />
            </div>
            <div>Use the command</div>
            <div className="bg-black/20 rounded-lg p-2">/tellme</div>
          </div>

          {/* <img
            src={require("../../assets/images/slackGuide.png")}
            alt="slack guide"
          /> */}
        </div>
      </Root>
    </Overlay>
  );
}

const Root = styled.div`
  background-color: #fff;
  border-radius: 22px;
  max-height: 90vh;
  width: 90vw;
  max-width: 1000px;
  overflow: auto;

  img {
    height: 260px;
    width: auto;
    object-fit: contain;
  }
`;
