import { post } from "network";

export const getDashboardStatsApi = async ({ token, data }: { token: string; data: { projectUid: string } }) => {
  return post({
    route: "/qa-master/v1/getStats",
    data: JSON.stringify({ project_uid: data.projectUid }),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
