import { post } from "network";

export const getShareLinkApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/getWorkSpaceShareLink",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const refreshShareLinkApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/refreshShareLinkHash",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getProjectIntegrationIdsApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/api/v1/getProjectIntegrationIds",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getOtherIntegrationApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/api/v1/getOtherIntegration",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const enableTelegramApi = async ({ token, ...raw }: { token: string; project_uid: string; telegram_user_id: string }) => {
  return post({
    route: "/api/v1/telegram/enableTelegram",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const enableDiscordApi = async ({ token, ...raw }: { token: string; project_uid: string; guild_id: string; permission?: "2147486720" }) => {
  return post({
    route: "/api/v1/discord/enableDiscord",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const enableSlackApi = async ({ token, ...raw }: { token: string; project_uid: string; code: string }) => {
  return post({
    route: "/api/v1/slack/enableSlack",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const disableIntegrationApi = async ({ token, ...raw }: { token: string; project_uid: string; integration_id: any }) => {
  return post({
    route: "/api/v1/disableIntegration",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
