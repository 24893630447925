import { Alert, Button, CircularProgress, FormControl, FormLabel, IconButton, Input, Modal, ModalClose } from "@mui/joy";
import ConfirmModal from "components/ConfirmModal";
import { mainContent } from "components/variants";
import { useApp } from "context/AppContext";
import { useAuth } from "context/AuthContext";
import { createWorkspaceApi, deleteWorkspaceApi, getMyWorkspacesApi, updateWorkspaceApi } from "network/api/workspace";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ApiRes } from "types";
import { ProjectD } from "types/project";

export default function WorkspaceList() {
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState<ProjectD[]>([]);
  const { token } = useAuth();
  const { openUpgrade } = useApp();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [createOpen, setCreateOpen] = useState(false);
  const [limitErr, setLimitErr] = useState(false);

  const fetchWorkspaces = useCallback(async () => {
    if (!token) {
      return;
    }

    try {
      setLoading(true);
      const res = await getMyWorkspacesApi({ token });
      setLoading(false);

      if (res.type === ApiRes.SUCCESS) {
        setWorkspaces(res.data);
        setTotal(res.total);
      }
    } catch (err) {
      setLoading(false);
      console.error("Err", err);
    }
  }, [token]);

  useEffect(() => {
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  //CREATE
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setError("");
    setLimitErr(false);
  }, [createOpen]);

  const onCreate = async (e: any) => {
    e.preventDefault();
    const name = e.target?.elements[0].value;
    setError("");
    setLimitErr(false);

    try {
      setCreating(true);
      const res = await createWorkspaceApi({ token, name });

      if (res.type === ApiRes.SUCCESS) {
        setWorkspaces((s) => [res.data, ...s]);
        setCreateOpen(false);
        toast.success("Workspace created successfully!");
      } else {
        setError(res.message);
        if (res.status === 409) {
          setLimitErr(true);
        }
      }

      setCreating(false);
    } catch (err) {
      setCreating(false);
      console.error("Err", err);
    }
  };

  //UPDATE
  const [editNode, setEditNode] = useState<ProjectD | null>(null);

  const onUpdate = async (e: any) => {
    try {
      e.preventDefault();
      const name = e.target?.elements[0].value;

      if (!editNode) {
        return;
      }

      setCreating(true);
      const res = await updateWorkspaceApi({
        token,
        name,
        project_uid: editNode?.project_uid,
      });
      setCreating(false);

      if (res.type === ApiRes.SUCCESS) {
        setWorkspaces((s) =>
          s.map((i) => {
            if (i.project_uid === editNode?.project_uid) {
              return {
                ...i,
                ...res.data,
              };
            } else {
              return i;
            }
          })
        );

        setEditNode(null);
      }
    } catch (err) {
      setEditNode(null);

      console.error("Err", err);
    }
  };

  //DELETE
  const [deleteId, setDeleteId] = useState("");
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    try {
      setDeleting(false);

      const res = await deleteWorkspaceApi({ project_uid: deleteId, token });
      setDeleting(false);
      if (res.type === ApiRes.SUCCESS) {
        setDeleteId("");
        setWorkspaces((s) => s.filter((i) => i.project_uid !== deleteId));
      }
    } catch (err) {
      setDeleting(false);
      console.error("Err", err);
    }
  };

  return (
    <div className={mainContent({ class: "max-w-[1100px] mx-auto py-8" })}>
      <div className="mb-6 flex justify-between items-center">
        <h4 className="font-semibold text-xl ">
          My workspaces <small className="opacity-60">({total})</small>
        </h4>

        {workspaces.length !== 0 && (
          <Button
            onClick={() => {
              setCreateOpen(true);
            }}
          >
            Add new workspace
          </Button>
        )}
      </div>

      {loading ? (
        <div className="grid place-items-center py-20">
          <CircularProgress size="md" />
        </div>
      ) : (
        <>
          {workspaces.length === 0 ? (
            <div>
              <div className="w-full rounded-md  mx-auto border border-dashed border-gray-200 bg-gray-100 flex justify-center items-center p-4 flex-col min-h-[200px]">
                <p className="mb-2">You dont have any workspace yet create one</p>
                <Button
                  onClick={() => {
                    setCreateOpen(true);
                  }}
                >
                  Create Workspace
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="list grid grid-cols-1 gap-2 md:grid-cols-3 sm:grid-cols-2">
                {workspaces.map((i) => {
                  return (
                    <div
                      onClick={(e) => {
                        navigate("/workspace/" + i.project_uid);
                      }}
                      key={i.id}
                      className="item rounded-lg border-2 cursor-pointer border-gray-100 hover:bg-blue-50 bg-gray-50 hover:border-blue-500 p-4 transition-all"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <h4 className="font-medium">{i.name}</h4>
                        <div className=" flex gap-1">
                          <IconButton
                            onClick={(e) => {
                              setEditNode(i);
                              e.stopPropagation();
                            }}
                          >
                            <AiFillEdit />
                          </IconButton>
                          <IconButton
                            color="danger"
                            onClick={(e) => {
                              setDeleteId(i.project_uid);
                              e.stopPropagation();
                            }}
                          >
                            <AiFillDelete />
                          </IconButton>
                        </div>
                      </div>

                      <div>
                        <p className="text-sm">
                          {i?.total_file || 0} {Number(i?.total_file) === 1 ? "file" : "files"}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}

      <Modal
        open={createOpen || Boolean(editNode)}
        onClose={() => {
          setCreateOpen(false);
          setEditNode(null);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="bg-white relative w-[400px] max-w-[90%] rounded-lg px-4">
          <div className="header py-4 flex items-center">
            <div className="font-semibold">{editNode ? "Update" : "Create"}</div>
            <ModalClose variant="outlined" />
          </div>

          <div className="py-4">
            <form
              className="flex flex-col gap-3"
              onSubmit={(e) => {
                if (editNode) {
                  onUpdate(e);
                } else {
                  onCreate(e);
                }
              }}
            >
              <FormControl required>
                <FormLabel>Workspace name</FormLabel>
                <Input autoFocus type="text" name="name" defaultValue={editNode?.name} />
              </FormControl>

              {error && (
                <Alert color="danger" sx={{ px: 0, display: "inline-block" }} variant="plain">
                  <span className="">{error} </span>
                  {limitErr && (
                    <span
                      onClick={() => {
                        setCreateOpen(false);
                        setEditNode(null);
                        openUpgrade();
                      }}
                      className="underline font-bold cursor-pointer"
                    >
                      Upgrade now
                    </span>
                  )}
                </Alert>
              )}

              <Button loading={creating} type="submit">
                {editNode ? "Update" : "Create"}
              </Button>
            </form>
          </div>
        </div>
      </Modal>

      {/* DELETE  */}
      <ConfirmModal
        danger
        title="Delete this workspace ? "
        desc="This action is irreversible"
        onClose={() => {
          setDeleteId("");
        }}
        onConfirm={() => {
          onDelete();
        }}
        open={Boolean(deleteId)}
        loading={deleting}
      />
    </div>
  );
}
