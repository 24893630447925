import { tv } from "tailwind-variants";

export const mainContent = tv({
  base: "px-4",
  variants: {
    place: {
      dash: "py-4",
    },
  },
});
