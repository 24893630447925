import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Sheet,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { useAuth } from "context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmModal from "components/ConfirmModal";
import { useWorkspace } from "context/WorkspaceContext";
import {
  deleteProjectFileApi,
  getProjectFilesApi,
} from "network/api/workspace/file";
import { mainContent } from "components/variants";
import TablePagination from "components/TablePagination";
import { useDebounce } from "use-debounce";
import { ApiRes } from "types";
import { formatDateTime } from "utils/helpers";
import { MdInfo } from "react-icons/md";

enum UploadStatus {
  running = "running",
  success = "success",
  error = "error",
}

export default function FilesList() {
  const navigate = useNavigate();
  const { projectUid } = useWorkspace();
  const { pathname } = useLocation();
  const { token } = useAuth();
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [delaySearch] = useDebounce(search, 300);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const [deleting, setDeleting] = useState(false);

  const fetchFiles = useCallback(
    async (refetch = false) => {
      if (!projectUid) {
        return;
      }

      try {
        if (!refetch) {
          setLoading(true);
        }
        const res = await getProjectFilesApi({
          limit,
          page,
          token,
          project_uid: projectUid,
          search: delaySearch,
        });
        setLoading(false);

        if (res.type === ApiRes.SUCCESS) {
          setList(res.data);
          setTotal(res.total);
        }
      } catch (err) {
        setLoading(false);
        console.error("Err", err);
      }
    },
    [delaySearch, page, limit, token, projectUid]
  );

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  useEffect(() => {
    let delay: any;

    if (
      list.filter((file) => file.status === UploadStatus.running).length > 0
    ) {
      delay = setTimeout(() => {
        fetchFiles(true);
      }, 10000);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [list, fetchFiles]);

  const onDelete = async () => {
    try {
      setDeleting(true);
      const res = await deleteProjectFileApi({
        id: deleteId,
        project_uid: projectUid,
        token,
      });
      setDeleting(false);

      if (res.type === "RXSUCCESS") {
        setDeleteId("");
        setList((s) => s.filter((s) => s?.id?.toString() !== deleteId));
        setTotal((s) => s - 1);
      }
    } catch (err) {
      setDeleting(false);
      console.error("Err", err);
    }
  };

  return (
    <div className={mainContent({ place: "dash", class: "min-h[500px]" })}>
      <div className="flex justify-between mb-10">
        <div className="right">
          <Typography level="h6">Your Files</Typography>
          <Typography level="body2">{total} results</Typography>
        </div>

        <div className="right flex items-center gap-2">
          {list.length !== 0 && (
            <Button
              size="md"
              startDecorator={<FiPlus />}
              onClick={() => navigate(pathname + "/add")}
            >
              Add new
            </Button>
          )}
        </div>
      </div>
      {/* HEADER END  */}

      {list.length === 0 && !loading ? (
        <div className="flex flex-col justify-center gap-2 items-center">
          <p>You dont have any files.</p>

          <Button
            size="md"
            startDecorator={<FiPlus />}
            onClick={() => navigate(pathname + "/add")}
          >
            Add new
          </Button>
        </div>
      ) : (
        <div className="rounded-md">
          <Sheet
            sx={{
              borderRadius: 12,
            }}
          >
            {loading ? (
              <div className="flex h-20 items-center justify-center">
                <CircularProgress size="md" />
              </div>
            ) : (
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>ID</th>
                    <th>Filename</th>
                    <th>Created date</th>
                    <th>Status</th>
                    <th style={{ width: "10%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((i) => {
                    return (
                      <tr key={i?.id}>
                        <td>{i?.id}</td>
                        <td>{i?.name}</td>
                        <td>{formatDateTime(i.createdAt)}</td>
                        <td>
                          <div className="capitalize cursor-default">
                            <Tooltip
                              arrow
                              title={i?.status_info}
                              placement="bottom"
                              color={
                                i?.status === "error" ? "danger" : "neutral"
                              }
                            >
                              <Chip
                                variant="solid"
                                color={
                                  i?.status === "error"
                                    ? "danger"
                                    : i?.status === "success"
                                    ? "success"
                                    : "info"
                                }
                                startDecorator={<MdInfo size={20} />}
                              >
                                {!i?.status ? "Pending" : i?.status}
                              </Chip>
                            </Tooltip>
                          </div>
                        </td>
                        {/* <td>{formatDateTime(i.createdAt)}</td> */}
                        <td>
                          <div className="flex gap-1">
                            <IconButton
                              variant="outlined"
                              color="danger"
                              onClick={() => setDeleteId(i?.id?.toString())}
                            >
                              <AiFillDelete />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={5}>
                      <TablePagination
                        {...{ limit, page, total }}
                        onPage={setPage}
                        onLimit={(l: number) => {
                          setLimit(l);
                        }}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            )}
          </Sheet>
        </div>
      )}

      <ConfirmModal
        open={deleteId ? true : false}
        onClose={() => setDeleteId("")}
        danger
        confirmTitle="Delete file"
        loading={deleting}
        onConfirm={() => onDelete()}
        desc="This action is irreversible"
        title="Delete this file"
      />
    </div>
  );
}
