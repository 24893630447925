import styled from "styled-components";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/confetti-animation.json";
import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { THEME } from "utils/theme";

export default function PaymentSuccess() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const navigate = useNavigate();

  return (
    <Root>
      <div
        className="absolute w-full"
        style={{ left: "50%", transform: "translateX(-50%)" }}
      >
        <Lottie options={defaultOptions} height={500} width="100%" />
      </div>
      <Card>
        <Tick>
          <IoIosCheckmarkCircle />
        </Tick>

        <div className="font-bold text-2xl border-b border-dashed w-full text-center pb-6">
          Payment Success!
        </div>

        <div className="text-center max-w-[300px]">
          Congratulations! Your payment has been successfully processed. If you
          have any questions or concerns, please don't hesitate to contact us.
          We appreciate your business and hope you enjoy your purchase!
        </div>

        <Button
          variant="soft"
          color="success"
          onClick={() => {
            navigate(`/`);
          }}
        >
          Go to Workspaces
        </Button>
      </Card>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  height: calc(100vh - ${THEME.appbarHeight.outer}px);
  place-content: center;
`;
const Card = styled.div`
  padding: 2em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  min-width: 350px;
  min-height: 400px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 80%;
    width: 100%;
    font-size: 14px;
    padding: 0.5em 0;

    span:last-child {
      font-weight: 700;
      opacity: 100%;
    }
  }

  animation-name: zoom-in;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: center center;

  @keyframes zoom-in {
    from {
      transform: scale(0.4);
    }
    to {
      transform: scale(1);
    }
  }
`;
const Tick = styled.div`
  color: hsl(141, 72%, 36%);
  font-size: 36px;
  border-radius: 100%;
  width: 60px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  background-color: rgba(23, 140, 64, 0.15);
`;
