import React from "react";
import { tv } from "tailwind-variants";

export default function Button({ children, color, className, type, onClick }: { children: any; className?: string; loading?: boolean; color?: "primary" | "gray" | "red" | "dark"; type?: "light" | "link"; onClick?: (e: any) => any }) {
  return (
    <button onClick={onClick} className={rootV({ class: className, color: color, type: type })}>
      {children}
    </button>
  );
}

const rootV = tv({
  base: "bg-blue-500 p-[8px] px-3 rounded-lg text-white cursor-pointer hover:bg-blue-700 transition-all font-medium flex gap-2 items-center",
  variants: {
    color: {
      gray: "bg-gray-100 text-gray-800 hover:bg-gray-200",
      primary: "",
      red: "text-white bg-red-600",
      dark: "bg-gray-700 text-gray-200 hover:bg-gray-800",
    },

    type: {
      light: "bg-blue-500/10 text-blue-500 hover:bg-blue-500/20",
      link: "bg-transparent text-blue-500 hover:text-blue-600 hover:bg-transparent px-0",
    },
  },
  compoundVariants: [
    {
      color: "primary",
      type: "link",
      class: "bg-green-100 text-green-700 dark:text-green-800", // You can also use "className"
    },
  ],
});
