import PlansList from "../PlansList";
import Overlay from "./Overlay";

export default function PlansModal({
  message,
  open,
  onClose,
}: {
  message: string;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Overlay open={open} onClose={onClose}>
      <PlansList message={message} />
    </Overlay>
  );
}
