import React from "react";
import { RouterProvider } from "react-router";
import { router } from "router";
import { Toaster } from "react-hot-toast";
import { NextUIProvider } from "@nextui-org/react";

function App() {
  return (
    <NextUIProvider>
      <div className="App">
        <div className="bg-red-500 text-center py-1 text-white">QA Master is depreciated. We no longer maintain this project.</div>
        <RouterProvider router={router} />
        <Toaster />
      </div>
    </NextUIProvider>
  );
}

export default App;
