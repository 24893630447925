import Appbar from "components/layouts/Appbar";
import styled from "styled-components";
import { THEME } from "../utils/theme";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      <Appbar />
      <Root>
        <h1>Page Not Found</h1>
        <img src={require("../assets/images/404.png")} alt="Page not found" />
        <Link to="/">
          <button>Go to Home</button>
        </Link>
      </Root>
    </div>
  );
}

const Root = styled.div`
  height: calc(100vh - ${THEME.appbarHeight.outer}px);
  display: grid;
  place-content: center;
  justify-items: center;

  h1 {
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
  }

  img {
    width: 320px;
  }

  button {
    padding: 20px 40px;
    background: #4e55f1;
    border-radius: 120px;
    color: #fff;
    font-size: 18px;
  }
`;
