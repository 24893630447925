import styled from "styled-components";
import Overlay from "./Overlay";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsDiscord } from "react-icons/bs";

export default function DiscordModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Overlay open={open} onClose={onClose}>
      <Root>
        <div className="px-6 md:px-12 lg:px-16 py-4 bg-[#5865F2] text-white flex items-center gap-4">
          <BsDiscord className="text-4xl" />
          <div className="font-semibold text-xl">Discord Integration</div>
        </div>

        <div className="px-6 md:px-12 lg:px-16 py-8 flex flex-col gap-3">
          <h3 className="font-semibold text-lg mb-2">How to use QA Master Discord Bot?</h3>

          <div className="flex items-center gap-2 font-semibold">
            <div className="bg-[#4E55F1] flex items-center text-white text-2xl justify-center p-1 rounded-full">
              <MdKeyboardArrowRight />
            </div>
            <div>Use the command</div>
            <div className="bg-black/20 rounded-lg p-2">/tellme</div>
            <div>+ Enter/Tab</div>
          </div>

          <div className="flex-1">
            <img src={require("../../assets/images/discordGuide.png")} alt="discord guide" />
          </div>
        </div>
      </Root>
    </Overlay>
  );
}

const Root = styled.div`
  background-color: #fff;
  border-radius: 22px;
  max-height: 90vh;
  width: 90vw;
  max-width: 1000px;
  overflow: hidden;

  img {
    height: 220px;
    width: auto;
    object-fit: contain;
  }
`;
