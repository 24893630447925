import { Alert, Button, CircularProgress } from "@mui/joy";
import ConfirmModal from "components/ConfirmModal";
import { useAuth } from "context/AuthContext";
import { useWorkspace } from "context/WorkspaceContext";
import {
  getShareLinkApi,
  refreshShareLinkApi,
} from "network/api/workspace/integration";
import { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoCopy } from "react-icons/io5";
import { ApiRes } from "types";

export default function LinkSection() {
  const { projectUid } = useWorkspace();
  const { token } = useAuth();
  const [refreshConfirm, setRefreshConfirm] = useState(false);
  const [coppied, setCoppied] = useState(false);
  const [hash, setHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshSuccess, setRefreshSuccess] = useState(false);

  const link = `https://qamaster.yourgpt.ai/share/${hash}`;

  const onRefresh = async () => {
    try {
      if (!projectUid || !token) {
        return;
      }
      setRefreshConfirm(false);

      setRefreshing(true);
      const res = await refreshShareLinkApi({ project_uid: projectUid, token });

      if (res.type === ApiRes.SUCCESS) {
        setHash(res.data.hash);
        setRefreshSuccess(true);
        setTimeout(() => {
          setRefreshSuccess(false);
        }, 5000);
      }
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      console.error("Err", err);
    }
  };

  useEffect(() => {
    const fetchLink = async () => {
      if (!projectUid || !token) {
        return;
      }
      try {
        setLoading(true);
        const res = await getShareLinkApi({ token, project_uid: projectUid });
        setLoading(false);

        if (res.type === ApiRes.SUCCESS) {
          setHash(res.data.hash);
        }
      } catch (err) {
        setLoading(false);
        console.error("Err", err);
      }
    };

    if (token && projectUid) {
      fetchLink();
    }
  }, [token, projectUid]);

  return (
    <>
      <div className=" p-6  rounded-lg">
        <div className="mb-4 box">
          <h4 className="font-semibold text-lg"> Collaboration Link</h4>
          <p className="text-gray-500">
            Share this link with user so that they can collaborate with your QA
            Master and ask quries
          </p>
        </div>
        <div className="box">
          <div className="flex items-center border-gray-200 bg-gray-100 rounded-lg p-1 mb-4 text-base gap-2">
            <div
              className={`bg-gray-300 rounded-lg mr-2  self-stretch  transition-all cursor-pointer aspect-square h-[42px] flex items-center justify-center ${
                coppied
                  ? "bg-green-700/20 text-green-700"
                  : "hover:bg-gray-600 hover:text-white "
              } `}
              onClick={() => {
                navigator.clipboard?.writeText(link);
                setCoppied(true);
                setTimeout(() => {
                  setCoppied(false);
                }, 3000);
              }}
            >
              {coppied ? <BsCheckCircleFill size={20} /> : <IoCopy size={20} />}
            </div>
            <div> {loading ? <CircularProgress size="sm" /> : link}</div>
          </div>

          {refreshSuccess && (
            <Alert color="success" size="sm" sx={{ mb: 2 }}>
              Link updated
            </Alert>
          )}

          <Button onClick={() => setRefreshConfirm(true)} loading={refreshing}>
            Refresh Link
          </Button>
        </div>
      </div>

      <ConfirmModal
        title="Refresh integration link ?"
        desc="By performing refresh the current link will become invalid and can not be used for queries"
        onConfirm={onRefresh}
        onClose={() => setRefreshConfirm(false)}
        open={refreshConfirm}
        confirmTitle="Yes Refresh"
      />
    </>
  );
}
