import { Menu, MenuItem } from "@mui/joy";
import { mainContent } from "components/variants";
import { useApp } from "context/AppContext";
import { useAuth } from "context/AuthContext";
import React, { useState } from "react";
import { BsFolderFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import { THEME } from "utils/theme";
import Logo from "assets/svg/qa-master-logo.svg";

export default function Appbar({ workspace = false, title = "" }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rightMenuOpen = Boolean(anchorEl);
  const { activePlan } = useApp();
  const { logout } = useAuth();

  return (
    <div style={{ height: THEME.appbarHeight.outer }} className={mainContent({ class: "bg-white shadow-sm" })}>
      <div className="h-full flex justify-between items-center">
        {title ? (
          <div className="flex gap-3 items-center">
            <BsFolderFill size={22} className="text-gray-600" />
            <span className="font-semibold">{title}</span>
            {/* <span className="text-sm text-gray-500"> ( Workspace )</span> */}
          </div>
        ) : (
          <Link to="/" className="font-bold">
            <img src={Logo} style={{ height: 38 }} />
          </Link>
        )}

        {/* {!workspace && (
          <div>
            <Link to={"/workspaces"}>Workspaces</Link>
          </div>
        )} */}
        <div
          className="flex self-stretch items-center hover:bg-gray-200 px-2 transition-all cursor-pointer"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <FaUserCircle />
          <div className="font-medium mr-3 ml-2">Account</div>

          <FiChevronDown />
        </div>
      </div>
      <Menu size="md" id="basic-menu" sx={{ minWidth: 260 }} anchorEl={anchorEl} open={rightMenuOpen} onClose={() => setAnchorEl(null)} aria-labelledby="basic-demo-button">
        {activePlan && (
          <Link
            to="/billing"
            className="hover:bg-gray-200 py-2 px-3"
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            Billing
          </Link>
        )}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            logout();
          }}
          color="danger"
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
