import React from "react";
import { Link, NavLink } from "react-router-dom";
import { tv } from "tailwind-variants";

const itemV = tv({
  base: "flex gap-2 text-white/80 hover:text-white items-center py-3 px-2  hover:bg-white/10 rounded-md",
  variants: {
    type: {
      active: "bg-white/20 text-white  hover:bg-white/20 ",
    },
  },
});

export default function MenuItem({ icon, label, link }: { icon: any; label: string; link: string }) {
  return (
    <div>
      <NavLink to={link} className={({ isActive }) => itemV({ type: isActive ? "active" : undefined })}>
        <div>{icon}</div>
        {label}
      </NavLink>
    </div>
  );
}
