import { mainContent } from "components/variants";
import { useAuth } from "context/AuthContext";
import { useWorkspace } from "context/WorkspaceContext";
import { getProjectIntegrationIdsApi } from "network/api/workspace/integration";
import { useEffect, useState } from "react";
import { ApiRes } from "types";
import LinkSection from "./LinkSection";
import IntegrationCard from "./Card";
import { tv } from "tailwind-variants";
import { INTEGRATIONS } from "utils/constants";
import { IntegrationId } from "types/enum";
import { useSearchParams } from "react-router-dom";
import DiscordModal from "components/Modals/DiscordModal";
import SlackModal from "components/Modals/SlackModal";

export default function Integration() {
  const { projectUid } = useWorkspace();
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [integrationArr, setIntegrationArr] = useState(INTEGRATIONS);
  const [searchParams, setSearchParams] = useSearchParams();
  const integrationType = searchParams.get("type");

  // FUNCTIONS
  const onEnable = (id: IntegrationId) => {
    setIntegrationArr(
      integrationArr.map((item) => {
        if (item.id === id) {
          return { ...item, type: "my" as const };
        } else {
          return item;
        }
      })
    );
  };
  const onDisable = (id: IntegrationId) => {
    setIntegrationArr(
      integrationArr.map((item) => {
        if (item.id === id) {
          return { ...item, type: "other" as const };
        } else {
          return item;
        }
      })
    );
  };

  // SIDE EFFECTS
  useEffect(() => {
    const fetchMy = async () => {
      try {
        if (!projectUid) return;
        setLoading(true);
        const res = await getProjectIntegrationIdsApi({
          project_uid: projectUid,
          token,
        });
        setLoading(false);

        if (res.type === ApiRes.SUCCESS) {
          const myIds = res?.data;
          const myItems = INTEGRATIONS.filter((item) =>
            myIds.includes(item.id)
          );
          const otherItems = INTEGRATIONS.filter(
            (item) => !myIds.includes(item.id)
          );
          setIntegrationArr([
            ...myItems.map((item) => ({ ...item, type: "my" as const })),
            ...otherItems,
          ]);
        }
      } catch (err) {
        setLoading(false);
        console.error("Err", err);
      }
    };

    if (token && projectUid) {
      fetchMy();
    }
  }, [token, projectUid]);

  return (
    <div className={mainContent({ place: "dash" })}>
      <div className="p-6 rounded-lg mb-8">
        <div className="mb-4">
          <h4 className="font-semibold text-lg">
            <h4>Integration</h4>
          </h4>
          <p className="text-gray-500">
            Easily integrate QA Master with you favorite tools and apps for
            seamless collaboration.
          </p>
        </div>
        <div className={boxes()}>
          {integrationArr.map((i) => {
            if (loading) {
              return (
                <div className="h-[136px] w-full animate-pulse bg-gray-200 rounded-lg" />
              );
            } else {
              return (
                <IntegrationCard
                  key={i?.id}
                  {...{
                    ...i,
                    type: i.type,
                    onEnable: onEnable,
                    onDisable: onDisable,
                  }}
                />
              );
            }
          })}
        </div>
      </div>

      <LinkSection />
      <DiscordModal
        open={integrationType === "discord"}
        onClose={() => {
          searchParams.delete("type");
          setSearchParams(searchParams);
        }}
      />
      <SlackModal
        open={integrationType === "slack"}
        onClose={() => {
          searchParams.delete("type");
          setSearchParams(searchParams);
        }}
      />
    </div>
  );
}

const boxes = tv({
  base: "grid-cols-4 gap-3 grid",
});
