import { useApp } from "context/AppContext";
import { intervalToDuration, isPast } from "date-fns";
import { SubscriptionStatus } from "types/subscription";

export default function InfoBar() {
  const { activePlan, openUpgrade } = useApp();

  const getDays = (date: Date | string | number | null) => {
    if (!date) return null;

    let targetDateTime;
    if (typeof date === "number") {
      targetDateTime = new Date(date * 1000);
    } else {
      targetDateTime = new Date(date);
    }

    if (isPast(targetDateTime)) {
      return -1;
    }

    const currentDate = new Date();

    let daysDifference = intervalToDuration({
      start: currentDate,
      end: targetDateTime,
    });
    let days = null;
    if (daysDifference) {
      if (daysDifference.hours && daysDifference.days) {
        days = daysDifference.days + 1;
      } else {
        days = daysDifference.days;
      }
    }
    return days;
  };
  const getTrailingDaysLeft = () => {
    if (
      activePlan?.status === SubscriptionStatus.trialing &&
      activePlan?.trail_plan &&
      activePlan?.trail_plan?.expiry_date
    ) {
      const days = getDays(activePlan?.trail_plan?.expiry_date);
      return days;
    } else return null;
  };
  const getSubscriptionDaysLeft = () => {
    if (activePlan?.status === SubscriptionStatus.active) {
      const days = getDays(activePlan?.next_billing_cycle?.period_end);
      return days && days <= 2 ? days : null;
    } else return null;
  };

  const trialDays = getTrailingDaysLeft();
  const subscriptionDays = getSubscriptionDaysLeft();

  if (trialDays !== null || subscriptionDays !== null) {
    return (
      <div
        className={`${
          (trialDays && trialDays < 0) || subscriptionDays === 0
            ? "bg-red-500 text-white"
            : "bg-yellow-300 text-black"
        } flex justify-center py-2 font-semibold`}
      >
        <div>
          {trialDays && trialDays !== null
            ? trialDays === 0
              ? "Your trail plans ends today. "
              : trialDays < 0
              ? "Your trail has expired please upgrade. "
              : `Your trail plan ends in ${trialDays} days. `
            : subscriptionDays && subscriptionDays !== null
            ? subscriptionDays === 0
              ? "Your plan ends today. "
              : subscriptionDays < 0
              ? "Your plan has expired please upgrade. "
              : `Your plan ends in ${subscriptionDays} days. `
            : ""}
          <div
            onClick={() => openUpgrade()}
            className="cursor-pointer font-semibold underline inline"
          >
            Upgrade Plan{" "}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
