import { TextareaAutosize } from "@mui/base";
import { Alert, LinearProgress } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";

export default function ActionBar({ error, onMessage, message }: { error?: string; onMessage: (text: string) => any; message?: string }) {
  const loading = false;
  const [text, setText] = useState("");
  const inputRef = useRef<any>();

  useEffect(() => {
    if (message) {
      setText(message);
      inputRef.current?.focus();
    }
  }, [message]);

  return (
    <div
      className="absolute bottom-0 flex w-full flex-col gap-1"
      style={{
        background: `linear-gradient(360deg, rgba(255,255,255,1) 71%, rgba(255,255,255,0.7441570378151261) 90%, rgba(255,255,255,0) 100%)`,
      }}
    >
      <div className="pt-9">
        {error && (
          <Alert variant="soft" size="sm" sx={{ mb: 1 }} color="danger">
            {error}
          </Alert>
        )}
        {loading && <LinearProgress sx={{ borderRadius: 0 }} />}

        <div className="relative flex  items-center self-stretch p-4 max-w-[90%] mx-auto">
          <TextareaAutosize
            ref={inputRef}
            disabled={loading}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                // onSend();
                setText("");
                text.trim() && onMessage(text);
              }
            }}
            maxRows={4}
            minRows={1}
            autoFocus
            placeholder="Type here..."
            className="w-full  resize-none border-solid  shadow-sm  py-4 px-4 pr-[50px] text-base rounded-xl  border  outline-none transition-all  active:border-gray-200 focus:shadow-md "
            style={{ fontFamily: "inherit" }}
          />
          <div
            className="group absolute right-6 flex aspect-square h-[38px] cursor-pointer items-center justify-center overflow-hidden rounded-full bg-blue-500 text-white/90 text-gray-500 transition-all hover:bg-blue-600 hover:text-white"
            onClick={() => {
              if (text.trim()) {
                onMessage(text);
                setText("");
              }
            }}
          >
            <div className="group-hover:animate-slideRight">
              <IoSend />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
