// import ChatPage from "components/ChatPage";
import ChatPage from "components/ChatPageV2";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function Chatbot() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const p = params.get("uid");

  return (
    <div>
      <ChatPage
        onSessionCreate={(id) => {
          if (id) {
            navigate("?uid=" + id, { replace: true });
          }
        }}
        sessionUid={p || ""}
        place="query"
      />
    </div>
  );
}
