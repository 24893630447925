import WorkspaceList from "./WorkspaceList";
import { useApp } from "context/AppContext";
import PlansList from "components/PlansList";
import LoadingDots from "components/LoadingDots";

export default function Workspaces() {
  const { activePlan, loadingPlan, trialExpired } = useApp();

  return (
    <div>
      {loadingPlan ? (
        <div className="min-h-[400px] flex justify-center items-center flex-col gap-2">
          <div className="text-gray-500">Loading</div>
          <LoadingDots />
        </div>
      ) : (
        <>
          {!activePlan?.status && !activePlan?.trail_plan ? (
            <div className="pt-12 px-3">
              <div className=" text-center mb-8">
                <h4 className="font-semibold text-2xl mb-1">🎊 Welcome 🎊</h4>
                <p className="text-gray-500">
                  Welcome to QA Master select your plan to get started with
                  exciting features
                </p>
              </div>

              <PlansList />
            </div>
          ) : trialExpired ? (
            <div className="pt-12 px-3">
              <PlansList />
            </div>
          ) : (
            <WorkspaceList />
          )}
        </>
      )}
    </div>
  );
}
