import PlansList from "components/PlansList";
import React from "react";

export default function PlansPage() {
  return (
    <div className="py-8 px-4">
      <PlansList />
    </div>
  );
}
