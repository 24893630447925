import { post } from "network";
import { isAndroid, isIOS, isMacOs, isMobile, isWindows } from "react-device-detect";
import { SortD } from "types";

export const createShareLinkSessionApi = async ({ ...raw }: { hash: string }) => {
  // ENUM('android', 'ios', 'window', 'linux', 'mac')
  const device_type = isMobile ? "mobile" : "desktop",
    platform = isMacOs ? "mac" : isAndroid ? "android" : isWindows ? "window" : isIOS ? "ios" : "linux";

  return post({
    route: "/api/v1/createShareLinkSession",
    data: JSON.stringify({ ...raw, device_type, platform }),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const getProjectSessionsApi = async ({ token, ...raw }: { token: string; project_uid?: string; status: any; page?: number; limit?: number; orderBy?: SortD; search?: string }) => {
  return post({
    route: "/api/v1/getProjectSession",
    data: JSON.stringify({ ...raw }),
    config: {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  });
};

export const getSessionMessagesBySessionIdApi = async ({ raw, token }: { token: string; raw: any }) => {
  return post({
    route: "/api/v1/getSessionMessagesBySessionId",
    data: JSON.stringify(raw),
    config: {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  });
};
export const getSharedLinkMessagesBySessionIdApi = async (raw: { session_uid: string; hash: string; page: number; limit: number; orderBy: "desc" | "asc" | string }) => {
  return post({
    route: "/api/v1/getShareLinkMessagesBySessionId",
    data: JSON.stringify(raw),
    config: {
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const getSessionDetailBySessionIdApi = async ({ token, ...raw }: { token: string; session_uid: string }) => {
  return post({
    route: "/api/v1/getSessionDetailBySessionId",
    data: JSON.stringify(raw),
    config: {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  });
};
export const sessionMessageSeenApi = async ({ token, ...raw }: { token: string; session_uid: string }) => {
  return post({
    route: "/api/v1/sessionMessageSeen",
    data: JSON.stringify(raw),
    config: {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  });
};

export const endSessionApi = ({ projectUid, widgetUid, sessionUid }: { projectUid: string; widgetUid: string; sessionUid: string }) => {
  const raw = new URLSearchParams();
  raw.append("project_uid", projectUid);
  raw.append("widget_uid", widgetUid);
  raw.append("session_uid", sessionUid);

  return post({
    route: `/api/v1/chatbot/endChatbotSession`,
    data: raw,
    config: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  });
};

export const rateMessageApi = (data: { messageId: string; session_uid: string; rate: "0" | "1" }) => {
  const raw = new URLSearchParams();
  raw.append("id", data.messageId);
  raw.append("session_uid", data.session_uid);
  raw.append("rate", data.rate);

  return post({
    route: `/api/v1/chatbot/rateMessage`,
    data: raw,
    config: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  });
};
