import { useState } from "react";
import styled from "styled-components";
import { tv } from "tailwind-variants";
import { ReactComponent as Bulb } from "assets/svg/bulb.svg";
import { BsCheck } from "react-icons/bs";
import { HiUserCircle } from "react-icons/hi";
import { MessageD } from "types/chat";
import {
  MdThumbDown,
  MdThumbDownOffAlt,
  MdThumbUp,
  MdThumbUpOffAlt,
} from "react-icons/md";
import { IoClipboardOutline } from "react-icons/io5";
import { marked } from "marked";
import LoadingDots from "components/LoadingDots";
import { rateMessageApi } from "network/api/workspace/session";
import { formatDateTime } from "utils/helpers";
import { format, isToday } from "date-fns";

export default function MessageItem({
  message,
  send_by,
  error = false,
  generating = false,
  createdAt,
  onSave,
  id,
  loading,
  sessionUid,
  rate: r1,
}: MessageD & { onSave?: (id: any) => any; id: any; sessionUid: string }) {
  const sent = send_by === "user";
  const text = message;

  const [rate, setRate] = useState<null | "1" | "0">(r1 || null);
  const [coppied, setCoppied] = useState(false);

  const formatMessage = (message: string | undefined) => {
    if (message) {
      const regex = /###(?!.*###)/;
      const result = message.replace(regex, "");
      return result;
    } else {
      return message;
    }
  };

  const onRate = async (r: "1" | "0") => {
    try {
      setRate(r);
      const res = await rateMessageApi({
        messageId: id,
        rate: r,
        session_uid: sessionUid,
      });
    } catch (err) {
      console.error("Err", err);
    }
  };

  return (
    <Root
      className={rootV({
        type: sent ? "sent" : "rec",
      })}
    >
      {/* {id} */}
      <div className="top flex gap-4 mb-2">
        <IconBox className="flex items-center justify-center">
          {sent ? <HiUserCircle size={24} /> : <Bulb />}
        </IconBox>

        <div className="flex-1 pt-1">
          {sent ? (
            <Text id={id} className="whitespace-pre-wrap break-all">
              {formatMessage(text)}
            </Text>
          ) : loading ? (
            <div className="mt-2">
              <LoadingDots />
            </div>
          ) : (
            <ParseToHtml
              className={error ? "error" : ""}
              dangerouslySetInnerHTML={{
                __html: marked.parse(text, {
                  breaks: true,
                  mangle: false,
                  headerIds: false,
                }),
              }}
            />
          )}
        </div>

        <Right className="flex flex-col gap-1 items-end">
          <div className="flex gap-2 items-center">
            {!sent && !generating && (
              <>
                <Icon
                  className={iconBtnV()}
                  onClick={() => {
                    navigator.clipboard?.writeText(text);
                    setCoppied(true);
                    setTimeout(() => {
                      setCoppied(false);
                    }, 3000);
                  }}
                >
                  {coppied ? <BsCheck /> : <IoClipboardOutline />}
                </Icon>
                {/* )} */}
                {/* {!sent && onSave && ( */}
                <Icon
                  className={iconBtnV()}
                  onClick={() => {
                    onRate("0");
                  }}
                >
                  {rate === "0" ? <MdThumbDown /> : <MdThumbDownOffAlt />}
                </Icon>
                {/* )} */}
                {/* {!sent && onSave && ( */}
                <Icon
                  className={iconBtnV()}
                  onClick={() => {
                    onRate("1");
                  }}
                >
                  {rate === "1" ? <MdThumbUp /> : <MdThumbUpOffAlt />}
                </Icon>
              </>
            )}
          </div>
          <div className="text-gray-400 text-xs">
            {isToday(new Date(createdAt))
              ? format(new Date(createdAt), "K:mm aaa")
              : formatDateTime(createdAt)}
          </div>
        </Right>
      </div>
      <div className="bottom"></div>
    </Root>
  );
}

const rootV = tv({
  base: " px-2 py-4",
  variants: {
    type: {
      sent: "",
      rec: "bg-gray-100",
    },
  },
});
const iconBtnV = tv({
  base: "h-[32px] aspect-square flex justify-center items-center rounded-md hover:bg-gray-200 text-gray-600 cursor-pointer",
});
const Root = styled.div`
  /* min-height: 300px; */
`;
const IconBox = styled.div`
  height: 34px;
  width: 34px;
  svg {
    height: 34px;
    aspect-ratio: 1;
  }
`;
const Text = styled.div``;
const Right = styled.div``;
const Icon = styled.div`
  svg {
    height: 20px;
    width: 20px;
  }
`;
const ParseToHtml = styled(Text)`
  &.error {
    color: red;
  }

  * {
    font-weight: normal;
  }

  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 5px 0;
    font-size: 15px;
    line-height: normal;
  }
  .parse-to-html {
    white-space: pre-line;
  }
  /* ============= table style =================== */
  table {
    border-collapse: collapse;
    /* border: 1px solid #ddd; */
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    padding: 3px 0;
    ::-webkit-scrollbar {
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #ccc8c8;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #777;
    }
    thead {
      background-color: rgb(255, 255, 255, 0.2);
    }
    th,
    td {
      border: 1px solid #ddd;
      padding: 4px;
      white-space: pre-wrap;
      font-size: 13px;
    }
    th {
      font-weight: bold;
    }
  }

  /* =============== pre and code tag style ============= */
  pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    line-height: 1.2;
  }
  code {
    white-space: normal;
  }

  /* ============= list style =================== */
  ul {
    padding-inline-start: 20px;
    li {
      padding: 3px 0;
      list-style-type: circle;
    }
  }
  ol {
    padding-inline-start: 20px;
    li {
      padding: 3px 0;
      list-style-type: decimal;
    }
  }

  /* ========================================== */
  blockquote p {
    border-left: 3px solid rgb(160, 170, 191);
    padding-left: 10px;
    margin: 5px 0;
  }
`;
