import { APP_ID } from "utils/constants";
import { log, logErr } from "utils/helpers";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "https://api.yourgpt.ai";
export const ENDPOINT = process.env.NODE_ENV === "development" ? endpoint : endpoint;

export const post = ({ route, data, config }: { route: string; data: any; config?: { headers: any } }) => {
  let raw = data;

  if (typeof data === "string") {
    raw = JSON.stringify({ ...JSON.parse(data), app_id: APP_ID });
  } else if (data instanceof FormData) {
    raw.append("app_id", APP_ID);
  }

  return fetch(ENDPOINT + route, {
    method: "POST", // or 'PUT'
    headers: {
      ...config?.headers,
    },
    body: raw,
  })
    .then(async (response) => {
      if (response?.status === 401) {
        window.location.href = "https://app.yourgpt.ai/login";
      }

      const jst = await response.json();
      return { ...jst, status: response?.status };
      // return jst;
    })
    .catch((err) => {
      // log('POST err', err);
      throw err;
    });
};
export const patch = ({ route, data, config }: { route: string; data: any; config: { headers: any } }) => {
  let raw = data;

  if (typeof data === "string") {
    raw = JSON.stringify({ ...JSON.parse(data), app_id: APP_ID });
  } else if (data instanceof FormData) {
    raw.append("app_id", APP_ID);
  }

  return fetch(ENDPOINT + route, {
    method: "PATCH", // or 'PUT'
    headers: {
      ...config.headers,
    },
    body: raw,
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .catch((err) => {
      // log('POST err', err);
      throw err;
    });
};
export const get = ({ route, config }: { route: string; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "GET", // or 'PUT'
    ...config,
  })
    .then((response) => response.json())
    .catch((err) => logErr(err));
};
export const put = ({ route, data, config }: { route: string; data: any; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "PUT",
    headers: {
      ...config.headers,
    },
    body: data,
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};
export const deleteReq = ({ route, config }: { route: string; config: { headers: any } }) => {
  return fetch(ENDPOINT + route, {
    method: "DELETE",
    headers: {
      ...config.headers,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.text();
      } else {
        const err = await response.json();
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};
