import { post } from "network";

export const deleteFileApi = async ({ token, ...raw }: { token: string; id: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/updateWorkSpace",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getProjectFilesApi = async ({ token, ...raw }: { token: string; limit: number; page: number; search?: string; project_uid?: string }) => {
  return post({
    route: "/qa-master/v1/getProjectFiles",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const deleteProjectFileApi = async ({ token, ...raw }: { token: string; id: any; project_uid: any }) => {
  return post({
    route: "/qa-master/v1/deleteFile",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const uploadProjectFileApi = async ({ token, ...raw }: { token: string; file_name: string; project_uid: string; original_filename: string }) => {
  return post({
    route: "/qa-master/v1/uploadFile",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getProjectFileSignedUrlApi = async ({ token, ...raw }: { token: string; file_name: string; project_uid: string }) => {
  return post({
    route: "/qa-master/v1/getProjectFileSignedUrl",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
