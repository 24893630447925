import { format, intervalToDuration, isPast } from "date-fns";

export const log = (...args: any[]) => {
  // return;
  return console.log(...args);
};
export const logErr = (...args: any[]) => {
  // return;
  return console.error(...args);
};

export const formatDateTime = (tm?: number | string | Date) => {
  if (!tm) {
    return;
  }
  if (typeof tm === "number") {
    return format(new Date(tm * 1000), "dd MMM yyyy, hh:MMaaa");
  } else if (tm) {
    return format(new Date(tm), "dd MMM yyyy, hh:MMaaa");
  }
};

export function isValidURL(str: string): boolean {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(str);
}

export function isValidFileType(str: string): boolean {
  const regex = /\.txt$|\.pdf$|\.doc$|\.docx$|\.ppt$|\.csv$/i;
  return regex.test(str);
}

export const isJSON = (str: string) => {
  if (!str) return false;
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export function timeAgo(lastMessageTimestamp: number) {
  const date1 = new Date();
  const date2 = new Date(Number(lastMessageTimestamp));
  const timeDifferenceMs = date1.getTime() - date2.getTime();
  const seconds = Math.floor(timeDifferenceMs / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + " year" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " month" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " day" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hr" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " min" + (interval === 1 ? "" : "s") + " ago";
  }
  return "Just now";
}

export function formatTimestamp(timestamp: any) {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  const inputYear = inputDate.getFullYear();
  const inputMonth = inputDate.getMonth();
  const inputDay = inputDate.getDate();

  const currentTime = inputDate
    .toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(" ", "");
  // .toUpperCase()

  if (
    inputYear === currentDate.getFullYear() &&
    inputMonth === currentDate.getMonth() &&
    inputDay === currentDate.getDate()
  ) {
    return `${currentTime}`;
  } else if (inputYear === currentDate.getFullYear()) {
    const monthName = inputDate.toLocaleString("en-US", {
      month: "long",
    });
    return `${currentTime},${inputDay} ${monthName}`;
  } else {
    const formattedDate = `${inputDay}-${inputMonth + 1}-${inputYear}`;
    return `${currentTime},${formattedDate}`;
  }
}

export function getDaysUntilDate(targetDate: Date | number | undefined) {
  if (!targetDate) return "";
  let targetDateTime;

  if (typeof targetDate === "number") {
    targetDateTime = new Date(targetDate * 1000);
  } else {
    targetDateTime = new Date(targetDate);
  }

  if (isPast(targetDateTime)) {
    return -1;
  }

  const currentDate = new Date();
  let daysDifference = intervalToDuration({
    start: currentDate,
    end: targetDateTime,
  });
  let days;
  if (daysDifference) {
    if (daysDifference.hours && daysDifference.days) {
      days = daysDifference.days + 1;
    } else {
      days = daysDifference.days;
    }
  }
  return days || 0;
}

export function formatNumberShort(number: number) {
  if (number >= 1000000) {
    const formattedNumber = (number / 1000000).toFixed(1);
    return formattedNumber.endsWith(".0")
      ? formattedNumber.slice(0, -2) + " Million"
      : formattedNumber + " Million";
  } else if (number >= 1000) {
    const formattedNumber = (number / 1000).toFixed(1);
    return formattedNumber.endsWith(".0")
      ? formattedNumber.slice(0, -2) + " Thousand"
      : formattedNumber + " Thousand";
  } else {
    return number.toString();
  }
}
